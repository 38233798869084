import React, { useState } from "react";
import PhoneInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import axios from "axios";

const Contact = () => {
  const [Error, setError] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
    country: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
    country: "",
  });

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);

  console.log("This is Formdata", formData.country.name);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log("This is value of event", e);
  };

  const handleTextValidation = (value) => {
    if (value !== null && /^[a-zA-Z\s]+$/.test(value)) {
      // Value is not null and contains only letters (uppercase and lowercase) and spaces
      return true;
    } else {
      return false;
    }
  };

  const handleBlur = (e) => {
    const fieldName = e.target.name;
    setError((prevError) => ({
      ...prevError,
      [fieldName]: !formData[fieldName].trim()
        ? "This is a required field!"
        : "",
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail.com$/;
    return emailRegex.test(email);
  };

  const handlePhoneNumberChange = (isValid, value, country) => {
    setIsValidPhoneNumber(isValid);
    setPhoneNumber(value);
    setFormData({ ...formData, phone: value, country: country });
  };

  const Confirmation = () => {
    return (
      <div
        className="p-3 mt-4 mb-4 text-sm text-green-800 rounded-xl bg-green-200  dark:bg-gray-800 dark:text-green-400"
        role="alert"
      >
        <span className="font-medium">Send Successfully!</span> <br />
        We will get back to you in 3-5 business days.
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      "This is validation value",
      handleTextValidation(formData.name)
    );
    if (handleTextValidation(formData.name)) {
      axios
        .post(
          "https://websiteportal.rajkamalbarscan.com/api/v1/contact/submitcontactdetails",
          formData
        )
        .then((res) => {
          if (res.data.status === true) {
            setShowConfirmation(true);
            setTimeout(() => {
              setShowConfirmation(false);
            }, 5000);
            setFormData({
              name: "",
              company: "",
              phone: "",
              email: "",
              message: "",
            });
          }
        });
    } else {
      console.log("Check text");
    }
  };

  return (
    <>
      <div className="container ">
        <h1 className="text-center mt-0 mb-5" id="contactus">
          Contact US
        </h1>
        <div className="row">
          <div className="col-md-6" id="contactus">
            <div id="left">
              <div className="formbox" style={{ padding: 0 }}>
                <form style={{ padding: 0 }}>
                  <input
                    name="name"
                    type="text"
                    className="form-control short my-4"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                      e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      e.target.placeholder = "Name";
                    }}
                  />
                  {Error.name && (
                    <p className="text-red-500 font-bold text-sm py-0">
                      {Error.name}
                    </p>
                  )}
                  {/* {handleTextValidation(formData.name) ? null : (
                    <p className="text-red-500 font-bold text-sm py-0">
                      Invalid Name!
                    </p>
                  )} */}
                  {console.log("This is formData", formData)}
                  <input
                    name="email"
                    type="text"
                    className="form-control short my-4"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => {
                      handleBlur(e);
                      e.target.placeholder = "Email";
                    }}
                  />
                  {Error.email && (
                    <p className="text-red-500 font-bold text-sm py-0">
                      {Error.email}
                    </p>
                  )}
                  {/* {validateEmail(formData.email) ? null : (
                    <p className="text-red-500 font-bold text-sm py-0">
                      Invalid Email Format!
                    </p>
                  )} */}
                  <input
                    name="company"
                    type="text"
                    className="form-control feedback-input my-4"
                    placeholder="Company"
                    value={formData.company}
                    onChange={handleInputChange}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => {
                      handleBlur(e);
                      e.target.placeholder = "Company";
                    }}
                  />
                  {Error.company && (
                    <p className="text-red-500 font-bold text-sm py-0">
                      {Error.company}
                    </p>
                  )}
                  {/* {handleTextValidation(formData.company) ? null : (
                    <p className="text-red-500 font-bold text-sm py-0">
                      This is a required field!
                    </p>
                  )} */}

                  <label className="col-xs-3 control-label">Phone number</label>
                  <PhoneInput
                    defaultCountry="auto"
                    preferredCountries={["us", "gb"]}
                    value={formData.phone}
                    placeholder="phone"
                    onPhoneNumberChange={handlePhoneNumberChange}
                    onBlur={(e)=>{
                      handleBlur(e);
                      e.target.placeholder = "phone";
                    }}
                  />
                   {/* {phoneNumber && (
                    <p className="text-red-500 font-bold text-sm py-0">
                      This  is Required field !
                    </p>
                  )} */}
                  {/* {console.log("this is Formdata value", formData.phone)} */}
                  {!isValidPhoneNumber && (
                    <div style={{ color: "red" }}>
                      Please enter a valid phone number
                    </div>
                  )}

                  <textarea
                    name="message"
                    className="form-control feedback-input my-3.5"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleInputChange}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Message")}
                  ></textarea>
                  <button
                    className="text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Send Message
                  </button>
                </form>
                {showConfirmation && <Confirmation />}
              </div>
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="google-map" style={{ padding: 0 }}>
              <iframe
                title="Google Map"
                allowFullScreen
                loading="lazy"
                src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=A%2036,%20Floor-first%20Floor,%20Giriraj%20Industrial%20Estate%20,Raj%20kamal%20Barscan,&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                style={{ padding: 0 }}
              ></iframe>
              <a href="https://gachanox.io/">Google Map</a>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
    .google-map {
      position: relative;
      width: 100%;
      height: 75%; 
      padding: 0; /* Remove padding */
      margin: 0; /* Remove margin */
    }

    .google-map iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0; /* Remove padding */
      margin: 0; /* Remove margin */
    }

    .formbox {
      padding: 0; /* Remove padding */
      margin: 0; /* Remove margin */
    }

    form {
      padding: 0; /* Remove padding */
      margin: 0; /* Remove margin */
    }
  `}
      </style>
    </>
  );
};

export default Contact;
