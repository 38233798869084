import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ReviewsList = () => {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    if (localStorage.getItem("caseStudy")) {
      localStorage.removeItem("caseStudy");
    }
    localStorage.setItem("caseStudy", JSON.stringify(item));
    console.log(item);
    navigate("/reviews_description");
  };

  useEffect(() => {
    const isCache = localStorage.getItem("caseStudies");
    if (isCache) {
      setData(JSON.parse(isCache));
      return;
    }

    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/case-studies/getcasestudies")
      .then((res) => {
        console.log(res.data.response);
        setData(res.data.response);
        localStorage.setItem("caseStudies", JSON.stringify(res.data.response));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <React.Fragment>
      <div className="w-full bg-white pt-16 px-4 relative">
        <div
          className="banner-image absolute top-0 left-0 w-full h-64 md:h-80 lg:h-96 bg-cover bg-center "
          style={{
            backgroundImage: `url('https://www.beyondkey.com/BkNew/images/casestudy/BIforNPOBanner.jpg')`,
          }}
        ></div>
        <h1 className="my-5 text-4xl font-extrabold leading-none tracking-tight text-center  md:text-5xl lg:text-6xl relative z-10">
          <span
            className="text-white"
            id="case_study"
            dangerouslySetInnerHTML={{
              __html:
                '<h1 style="font-weight: 700;text-white">Case Studies</h1>',
            }}
          />
        </h1>
        {/* <p className="mb-0 text-lg font-normal text-center text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html:
                '<span style="letter-spacing: 0.1em ;color:white">Our Comprehensive Range of Software Solutions</span>',
            }}
          />
        </p> */}
        <p className="mb-0 text-lg font-normal text-center text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html:
                '<span style="letter-spacing: 0.1em ;color:white">Discover transformative  success stories in our Case Studies, showcasing innovation and strategic investments.</span>',
            }}
          />
        </p>
        {/* <h1 className=" mt-24 mb-4 text-4xl font-extrabold leading-none tracking-tight text-center text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          Case Studies
        </h1>
        <p className="mb-6 text-lg font-normal text-center text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          Here at Rajkamal we focus on markets where technology, innovation,
          and capital can unlock long-term value and drive economic growth.
        </p> */}
      </div>

      <div className="w-full pl-5 pr-5 mx-auto grid md:grid-cols-3 pt-48 pb-28">
        {data.map((item, index) => (
          <div
            key={index}
            className="relative mx-4 my-4 max-w-sm p-6 bg-white rounded-xl rounded- shadow hover:dark:bg-gray-800"
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              <span
                className="font-poppins text-black"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </h5>
            <p className="mb-3 font-normal  dark:text-gray-400">
              <span
                className="font-poppins"
                dangerouslySetInnerHTML={{ __html: item.sub_title }}
                id="card_subtitle"
              />
            </p>
            <span
              className="absolute bottom-4 right-4 inline-flex items-center px-3 py-2 cursor-pointer text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => handleItemClick(item)}
            >
              Read more
            </span>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default ReviewsList;
