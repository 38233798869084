import React from "react";
import Laptop from "../assets/laptop.jpg";
import { Link } from "react-router-dom";

const Analytics = () => {
  return (
    <div className="w-full bg-white py-10 px-4 md:py-16 md:px-6 lg:px-8">
      <div className="max-w-screen-xl mx-auto grid md:grid-cols-2 gap-8">
        <img
          className="w-full md:w-[500px] mx-auto my-4"
          src={Laptop}
          alt="/"
        />
        <div className="flex flex-col justify-center">
          {/* <p className="text-[#00df9a] text-lg font-bold text-center md:text-left font-montserrat">
            Rajkamal Barscan Systems
          </p> */}
          <h1 className="font-poppins text-2xl sm:text-3xl md:text-4xl font-bold py-2 text-center md:text-left">
            View Our Work
          </h1>
          
          <p className="text-center md:text-left font-poppins">
            Explore our portfolio showcasing diverse projects that blend
            creativity and innovation. From cutting-edge designs to efficient
            solutions, our work reflects a commitment to excellence. Discover
            how we've helped clients achieve their goals through a combination
            of expertise and passion.
          </p>
          <div className="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start mt-4 md:mt-6">
            <Link to="/caseStudy">
              <button className="font-poppins mt-3 md:mt-0 bg-black text-[#00df9a] w-full md:w-[200px] rounded-md font-medium py-3 md:mx-2 md:my-0 hover:scale-105 duration-300">
                View Case Studies
              </button>
            </Link>
            <Link to="/Products">
              <button className="font-poppins mt-3 md:mt-0 bg-black text-[#00df9a] w-full md:w-[200px] rounded-md font-medium py-3 md:mx-2 md:my-0 hover:scale-105 duration-300">
                View Our Products
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
