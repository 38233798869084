// src/useBeforeUnload.js
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const useBeforeUnload = () => {
  const [cookies] = useCookies(["cookieName","userData"]);

  useEffect(() => {
    const handleScroll = () => {
      const userData = cookies.cookieName ? cookies.cookieName : null;
      const userData1 = cookies.userData ? JSON.parse(cookies.userData) : null;
      // console.log("This is userdata",cookies.userData)
      if (userData) {
        // Send the userData to your backend API
        // console.log("Api call to post data to backend", userData);
        // console.log("Api call to post data to backend", userData1);
        // fetch('https://your-backend-api.com/saveUserData', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify(userData)
        // });
      } else {
        // console.log("No userData Found", cookies.cookieName);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [cookies.cookieName]);
};

export default useBeforeUnload;
