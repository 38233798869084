import React from 'react';

const FileUploadComponent = ({ selectedFile, handleFileChange }) => {
  return (
    <div>
      <input
        type="file"
        id="file-input"
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        required
      />
      <label
        htmlFor="file-input"
        id="file-label"
        className={`inline-block py-2 px-4 mb-4 bg-blue-500 rounded-xl text-white cursor-pointer hover:bg-blue-700${
          selectedFile ? 'bg-green-500' : ''
        }`}
      >
        {/* {console.log("This is Selected File name",selectedFile.name)} */}
        {selectedFile ? `File selected: ${selectedFile.name}` : 'Choose File'}
      </label>
    </div>
  );
};

export default FileUploadComponent;
