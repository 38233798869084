import React from "react";
// import { useCookies } from 'react-cookie';
// import logo from "../assets/rkbs_logo.png";

const Footer = () => {
  // const [cookies, setCookie, removeCookie] = useCookies(['cookieName']);
  // const isCookiePresent = cookies.cookieName === 'user_tracking_id';
  // console.log("This is data",isCookiePresent)
  return (
    <>
      <footer
        className="z-10 text-center text-lg-start text-white "
        style={{ backgroundColor: "#000000" }}
      >
        {/* <h1>Cookie is present: {isCookiePresent ? 'Yes' : 'No'}</h1> */}
        <div className="container p-4">
          <section className="">
            <div className="row">
              <div className="col-md-6 col-lg-3 mt-3 text-left">
                <h6 className="font-montserrat text-uppercase mb-4 font-weight-bold text-white">
                  Rajkamal Barscan System Pvt Ltd
                </h6>
                <a>
                  <p className="text-white font-sans">
                    Rajkamal is united by a common goal: To simplify business
                    through technology which helps our customers achieve
                    exponential growth in the industry.
                  </p>
                </a>
              </div>
              <div className="col-md-6 col-lg-3 mt-3 ">
                <h6 className="text-uppercase mb-2 mt-2 font-weight-bold text-white">
                  Services
                </h6>
                <p>
                  <a href="/products" className="font-poppins">
                    Products
                  </a>
                </p>
                <p>
                  <a href="/Labels" className="font-poppins">
                    Labels
                  </a>
                </p>
                <p>
                  <a href="/Solutions" className="font-poppins">
                  Solutions
                  </a>
                </p>
                
              </div>
              <div className="col-md-6 col-lg-3 mt-3 ">
                <h6 className="text-uppercase mb-2 font-weight-bold mt-2 text-white">
                  Company
                </h6>
                <p>
                  <a href="/about">About</a>
                </p>
                <p>
                  <a
                    href="/EWaste"
                    target="blank"
                    className=" first-letter font-poppins"
                  >
                    E-Waste
                  </a>
                </p>
                <p>
                  <a href="/Career" className=" first-letter font-poppins">
                    Careers
                  </a>
                </p>
                <p>
                  <a href="/caseStudy" className="font-poppins">
                    Case Study
                  </a>
                </p>
                <p>
                  <a href="/Clients" className="font-poppins">
                    Clients
                  </a>
                </p>
                {/* <p>
                  <a href="/Contact" className=" first-letter font-poppins">
                    Contact
                  </a>
                </p> */}
              </div>

              <div className="col-md-6 col-lg-3 mt-3 ">
                <h6 className="text-uppercase mt-2 mb-2 font-weight-bold text-white">
                  Contact
                </h6>
                <div className="text-left">
                  <p>
                    <a
                      href="https://google.com/maps/place/Rajkamal+Barscan+Systems+Pvt.+Ltd/@19.1192722,72.8593686,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7c82ffb046311:0xc13748063e112765!8m2!3d19.1192671!4d72.8619489!16s%2Fg%2F1tf5j2_k?entry=ttu"
                      target="blank"
                    >
                      <i className="font-poppins fas fa-home mr-2 text-left"></i>
                      Mahakali Caves Road, Mumbai, Maharashtra
                    </a>
                  </p>
                  <p>
                    <a href="mailto:sales@rajkamalbarscan.com">
                      <i className="font-poppins fas fa-envelope mr-2"></i>{" "}
                      sales@rajkamalbarscan.com
                    </a>
                  </p>
                  <p>
                    <a href="tel:+912242147000">
                      <i className="font-poppins fas fa-phone mr-2"></i> +91
                      2242147000
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <hr className="my-3" />

          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 text-center text-md-start">
                <div className="p-3 font-poppins ">
                  © Copyright:
                  <a className="text-white font-poppins">
                    Rajkamal Barscan System Pvt Ltd
                  </a>
                </div>
              </div>
              <div className="col-md-5 text-center text-md-end">
                <a
                  href="https://www.facebook.com/rajkamalbarscans/?ref=embed_page"
                  target="_blank"
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f text-white"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/rajkamal-barscan-systems-pvt.-ltd/"
                  target="_blank"
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin text-white"></i>
                </a>
                <a
                  href="https://www.youtube.com/@Rajkamalbarscan"
                  target="_blank"
                  className="btn btn-outline-light btn-floating m-1 text-white"
                  role="button"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube text-white"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </>
  );
};

export default Footer;
