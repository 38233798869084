import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const { Component  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const login = JSON.parse(localStorage.getItem("login"));
    console.log("This is Login data", login);
    if (!login) {
      navigate("/TechAuthenticate");
    }
  });
  return (
    <>
      <Component />
    </>
  );
};

export default Protected;
