// import { createContext } from "react";

// export const loginContext=createContext({});

import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

export const CartProvider  = ({ children }) => {

  // const [isSun, setIsSun] = useState(
  //   localStorage.getItem("themePreference") === "dark"
  // );

  // const toggleTheme = () => {
  //   setIsSun((prevIsSun) => {
  //     const newIsSun = !prevIsSun;
  //     localStorage.setItem("themePreference", JSON.stringify(newIsSun));
  //     return newIsSun;
  //   });
  // };

  const [value, setValue] = useState('');

  // const addToCart = (product) => {
  //   setCart([...cart, product]);
  //   console.log("Cart",cart)
  // };
  // console.log("Cart data",cart)

  return (
    <CartContext.Provider value={{ value, setValue }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
