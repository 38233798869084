import React, { useState } from "react";
import FileUploadComponent from "./ChooseFile";
import axios from "axios";

export const Carrer_description_page = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const data = JSON.parse(localStorage.getItem("careers"));

  const [wordCount, setWordCount] = useState(0);

  const [formData, setFormData] = useState({
    floating_text: "",
    floating_phone: "",
    floating_email: "",
    message: "",
    jobID: data.job_number,
    pdfFile: null,
  });

  const handleFileChange = (event) => {
    const pdfFile = event.target.files[0];
    setFormData({ ...formData, pdfFile });
    setSelectedFile(pdfFile);
    // console.log("This is FormData",formData);
    // console.log("This is File",file);
  };

  console.log("This is JobID", formData.jobID);

  // console.log("This is file", formData.pdfFile);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (
        formData.floating_text &&
        formData.floating_email &&
        formData.floating_phone &&
        formData.message &&
        selectedFile
      ) {
        console.log("This is Formdata", formData);
        const careerFormResponse = await axios.post(
          "https://websiteportal.rajkamalbarscan.com/api/v1/careers/addcareers",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("This is career form response", careerFormResponse);

        if (careerFormResponse.data.status === true) {
          const incrementResponse = await axios.post(
            "https://websiteportal.rajkamalbarscan.com/api/v1/careers/incrementcareers",
            data
          );

          if (incrementResponse.data.status === true) {
            setShowConfirmation(true);
            setTimeout(() => {
              setShowConfirmation(false);
            }, 5000);

            setFormData({
              floating_text: "",
              floating_email: "",
              floating_phone: "",
              message: "",
              jobID: data.job_number,
            });
            console.log("ResponseCount incremented successfully");
          } else {
            console.log(incrementResponse.data);
          }

          setSelectedFile(null);
        } else {
          alert("Please fill out all required fields.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  const Confirmation = () => {
    return (
      <div
        className="p-3 mt-4 mb-4 text-sm text-green-800 rounded-xl bg-green-200  dark:bg-gray-800 dark:text-green-400"
        role="alert"
      >
        <span className="font-medium">Email Send Successfully!</span> <br />
        We will get back to you in 3-5 business days.
      </div>
    );
  };

  const handleTextareaChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    if (words.length <= 100) {
      setWordCount(words.length);
      setFormData({ ...formData, message: inputText });
    } else {
      event.preventDefault();
    }
  };

  return (
    <>
      {console.log(data)}
      <h2 className="font-poppins my-5 text-2xl font-extrabold leading-none tracking-tight text-center text-black md:text-5xl lg:text-6xl dark:text-white relative z-10">
        {data ? (
          <span
            dangerouslySetInnerHTML={{ __html: data.title }}
            id="about"
            style={{ fontWeight: 700 }}
          />
        ) : (
          "title"
        )}
      </h2>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2">
          <div className="container ">
            <div className="mb-4 mx-4 text-lg flex items-center">
              <h6 className="font-poppins font-extrabold">Job Role:</h6>
              <span className="ml-3 font-normal no-underline ">
                {data ? (
                  <span
                    className="font-poppins"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                    id="about"
                  />
                ) : (
                  "JobRole"
                )}
              </span>
            </div>
            <div className="mb-4 mx-4 text-lg flex items-center">
              <h6 className="font-extrabold mr-3">Salary:</h6>
              <span className="font-semibold">
                {data ? (
                  <span
                    className="font-poppins font-normal"
                    dangerouslySetInnerHTML={{ __html: data.salary }}
                    id="about"
                  />
                ) : (
                  "Salary"
                )}
              </span>
            </div>
            <div className="mb-4 mx-4 text-lg flex items-center">
              <h6 className="font-extrabold">Location:</h6>
              <span className="ml-3 font-semibold no-underline">
                {data ? (
                  <span
                    className="font-poppins font-normal"
                    dangerouslySetInnerHTML={{ __html: data.job_location }}
                    id="about"
                  />
                ) : (
                  "jobLocation"
                )}
              </span>
            </div>
            <p className="mb-4 mx-4 text-lg  underline">
              <h6 className="font-extrabold">Job Objective:</h6>
            </p>

            <div className="mx-4 my-4 text-base ">
              {data ? (
                <span
                  className="font-poppins"
                  dangerouslySetInnerHTML={{ __html: data.job_objective }}
                  id="about"
                />
              ) : (
                "jobObjective"
              )}
            </div>
            <br />
            <p className="mb-4 mx-4 text-lg  underline">
              <h6 className="font-extrabold">Job Brief:</h6>
            </p>

            <p className="mx-4 my-4 text-base ">
              {data ? (
                <span
                  className="font-poppins"
                  dangerouslySetInnerHTML={{ __html: data.job_brief }}
                  id="about"
                />
              ) : (
                "Job Brief"
              )}
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className="font-poppins w-full md:w-1/2 pb-5 pr-5">
          <a href="mailto:hr@rajkamalbarscan.com">
            <span className="font-montserrat  ml-3 mx-5 my-2 text-xl text-extrabold no-underline hover:text-[#00df9a]">
              hr@rajkamalbarscan.com
            </span>
          </a>
          <a href="mailto:hr@rajkamalbarscan.com">
            <span className="font-montserrat  ml-3 text-xl text-extrabold no-underline hover:text-[#00df9a]">
              +91 9765203426
            </span>
          </a>

          <div className="container bg-white py-4 px-5 rounded-xl">
            <form onSubmit={handleSubmit} method="post" action="" name="myForm">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="name1"
                  id="floating_text"
                  className="block py-2.5 px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  pattern="^[A-Za-z ]+$"
                  value={formData.floating_text}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      floating_text: e.target.value,
                    })
                  }
                />
                <label
                  htmlFor="floating_name"
                  className="peer-focus:font-medium absolute text-lg text-black dark:text-gray-400 duration-300 transhtmlForm -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Applicant Name:<span className="text-red-800">*</span>
                </label>

                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-300 focus:bg-black"></div>
              </div>

              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={formData.floating_email}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      floating_email: e.target.value,
                    })
                  }
                />
                <label
                  htmlFor="email"
                  className="text-black peer-focus:font-medium absolute text-lg dark:text-gray-400 duration-300 transhtmlForm -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email:<span className="text-red-800">*</span>
                </label>

                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-300 focus:bg-black"></div>
              </div>

              <div className="grid  md:gap-6">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    name="floating_phone"
                    id="floating_phone"
                    className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                    value={formData.floating_phone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        floating_phone: e.target.value,
                      })
                    }
                    required
                  />
                  <label
                    htmlFor="floating_phone"
                    className="text-black peer-focus:font-medium absolute text-lg  dark:text-gray-400 duration-300 transhtmlForm -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Phone number <span className="text-red-800">*</span>
                  </label>
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-300 focus:bg-black"></div>
                </div>
              </div>
              <p className="text-black   text-lg  dark:text-gray-400">
                Description:-<span className="text-red-500">*</span>
              </p>
              <div
                className=" my-4 wrap-input1 validate-input"
                data-validate="Message is required"
              >
                <textarea
                  className="input1"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleTextareaChange}
                ></textarea>
                <span className="shadow-input1"></span>
                <p className="text-gray-500 text-sm">
                  Word Count: {wordCount} / 100{" "}
                </p>
                {wordCount >= 100 && (
                  <p className="text-red-500 text-sm">Word limit reached</p>
                )}
              </div>
              <p className="text-black pb-2">
                Add Resume(PDF):-<span className="text-red-800">*</span>
              </p>
              {console.log("This is selctedFile", selectedFile)}
              <FileUploadComponent
                selectedFile={selectedFile}
                handleFileChange={handleFileChange}
              />
              <div className="container-contact1-form-btn">
                <button className="carrer-form-btn">
                  <span className="text-white">
                    Apply Now
                    <i
                      className="fa fa-long-arrow-right text-white"
                      aria-hidden="true"
                    ></i>
                  </span>
                </button>
              </div>
            </form>
            {showConfirmation && <Confirmation />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Carrer_description_page;
