import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import axios from "axios";

const Career = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const cachedCareers = localStorage.getItem("cachedCareers");
    if (cachedCareers) {
      setData(JSON.parse(cachedCareers));
      setLoading(false);
      return;
    }
  
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/careers/getcareers")
      .then((res) => {
        setData(res.data.response);
        setLoading(false);
        localStorage.setItem("cachedCareers", JSON.stringify(res.data.response));
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);
  

  const handleClick = async (item) => {
    if (localStorage.getItem("careers")) {
      localStorage.removeItem("careers");
    }
    localStorage.setItem("careers", JSON.stringify(item));
    await new Promise((resolve) => {
      resolve();
    });
    navigate("/career_description");
  };

  return (
    <div className="w-full  pt-16 px-4 relative">
      <div
          className="banner-image absolute top-0 left-0 w-full h-64 md:h-80 lg:h-72 bg-cover bg-center "
          style={{
            backgroundImage: `url('https://foxsecurityinc.com/wp-content/uploads/2023/07/careers-inner-page-banner.png')`,
          }}
        ></div>
        <h1 className="my-5 text-4xl font-extrabold leading-none tracking-tight text-left text-white md:text-5xl lg:text-6xl dark:text-white relative z-10">
          <span className="text-white font-poppins"
            dangerouslySetInnerHTML={{
              __html: '<h1 style="font-weight: 700;text-white">We invest in the world’s potential</h1>',
            }}
          />
        </h1>
        {/* <p className="mb-0 text-lg font-normal text-left text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html:
                '<span style="letter-spacing: 0.1em ;color:white">Here at Rajkamal we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</span>',
            }}
          />
        </p> */}

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: Unable to fetch data from the API.</p>
      ) : (
        <div className="max-w-[1240px] my-28 mb-0 py-4 mx-auto grid gap-6 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          
          {data.map((item, index) => (
            
            <div
              key={index}
              className="text-left font-poppins hover:scale-105 duration-200 hover:border-black mx-5 my-5 p-8 bg-white border border-gray-200 rounded-xl shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 flex items-center justify-between"
              onClick={() => handleClick(item)}
            >
              <h5 className="mb-0 text-3xl font-bold tracking-tight ">
                <span dangerouslySetInnerHTML={{ __html: item.title }} className="text-black" />
              </h5>
              <AiOutlineArrowRight className="ml-2 text-3xl hover:scale-105 duration-300 text-blue-500" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Career;