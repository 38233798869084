import React from "react";
import { useNavigate,Link } from "react-router-dom";
const Quad_img = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Products");
  };
  return (
    <>
      <div className="flex flex-wrap bg-white">
      <h1 className="font-poppins w-full mt-3 mb-3 text-center font-bold md:text-4xl sm:text-3xl text-2xl ">PRODUCTS</h1>  
        <div className="w-full md:w-1/2 ">
          <div
            className="grid grid-cols-2 gap-3 mx-4 my-4"
            onClick={handleClick}
          >
            <div className="relative cursor-pointer">
              <div className="group">
                <img
                  className=" rounded-lg transition-all duration-300 group-hover:scale-105 group-hover:blur-sm "
                  src="https://i.gifer.com/origin/54/540d0dec8df9e8b0445a4f37a13e24ce_w200.webp"
                  alt="image1"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <p className="text-white cursor-pointer opacity-90 hover:opacity-100 bg-black p-2 rounded-lg font-montserrat">
                    Barcode Scanner
                  </p>
                </div>
              </div>
            </div>

            <div className="relative cursor-pointer">
              <div className="group">
                <img
                  className="h-60 w-100 rounded-lg group-hover:scale-105 duration-300 group-hover:blur-sm"
                  src="https://miro.medium.com/v2/resize:fit:786/1*qwVn_rMHpNscq2rVYcPPbg.gif"
                  alt="image2"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0   hover:opacity-100 transition-opacity duration-300">
                  <p className="text-white cursor-pointer opacity-90 hover:opacity-100 bg-black p-2 rounded-lg font-serif">
                    RFID Solutions
                  </p>
                </div>
              </div>
            </div>
            <div className="relative cursor-pointer">
              <div className="group">
                <img
                  className="h-60 w-100 rounded-lg group-hover:scale-105 duration-300 group-hover:blur-sm"
                  src="https://media.tenor.com/2fXbn6Xtt0UAAAAC/software-software-development.gif"
                  alt="image 3"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <p className=" font-serif cursor-pointer text-white opacity-90 hover:opacity-100 bg-black p-2 rounded-lg ">
                    Software Solutions
                  </p>
                </div>
              </div>
            </div>
            <div className="relative cursor-pointer">
              <div className="group">
                <img
                  className="h-60 w-100 rounded-lg  group-hover:scale-105 duration-300  group-hover:blur-sm"
                  src="https://i.pinimg.com/originals/3a/97/44/3a974454a9828fa1a5aa0ee3a886a6dd.gif"
                  alt="image4"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                  <p className="text-white cursor-pointer bg-black font-serif opacity-90 hover:opacity-100 p-2 rounded-lg">
                    Label Printer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 text-center">
          <p className="mx-5 text-left font-poppins">
            Welcome to our software and technology products page, where
            innovation meets efficiency. Explore a curated selection of
            cutting-edge solutions designed to streamline processes, boost
            productivity, and harness automation. In software, discover
            applications tailored to meet digital demands, from productivity
            tools to advanced analytics. In a world where staying competitive is
            essential, our technology products provide a strategic advantage.
            Embrace the future of work, improve your processes, and stay at the
            forefront of your industry with our carefully curated lineup.
            Explore project management and cloud solutions that optimize
            workflows and empower remote work. Stay competitive in a fast-paced
            world, improve processes, and thrive through innovation and
            technology. Elevate efficiency, streamline operations, and transform
            with our products.
          </p>
          <Link to="/Products">
              <button className="font-poppins mt-3 bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 md:my-0 py-3 hover:scale-105 duration-300">
                View Our Products
              </button>
            </Link>
        </div>
      </div>
    </>
  );
};

export default Quad_img;
