import React from "react";
import error from "../assets/error.webp";

const Error = () => {
  return (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Bootstrap 5 404 Error Page</title>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <img className="w-auto h-80" src={error} alt="Error Message" />
          <p className="fs-3">
            <span className="text-danger">Page not Found<br/></span>Oops, Page under
            Maintenaince.
          </p>
          <p className="lead">
            The page you’re looking for is under maintenance
          </p>
          <a href="/" className="btn btn-primary">
            Go Home
          </a>
        </div>
      </div>
      <div />
    </>
  );
};

export default Error;
