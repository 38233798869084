import React, { useState, useEffect, useContext, useRef } from "react";
import { useCookies } from 'react-cookie';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";
import DropdownMenu from "./Dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/rkbs_logo.png";
import useBeforeUnload from './useBeforeUnload';

const Navbar = () => {
  useBeforeUnload();
  const [cookies] = useCookies(['cookieName']);
  const cookieValue = cookies.cookieName || 'Cookie not found';
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [nav, setNav] = useState(false);
  const navRef = useRef(null);
  // Parse the stored theme preference from localStorage
  const storedThemePreference = JSON.parse(
    localStorage.getItem("themePreference")
  );

  // Set the initial theme preference to 'light' if not found in localStorage
  const [isSun, setIsSun] = useState(
    storedThemePreference === "light" ? false : true
  );

  const navigate = useNavigate();

  const gotoHome = () => {
    navigate("/");
  };

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNav(false); // Close the drawer when clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMode = () => {
    setIsSun((prevIsSun) => {
      const newIsSun = !prevIsSun;
      localStorage.setItem(
        "themePreference",
        JSON.stringify(newIsSun ? "dark" : "light")
      );
      return newIsSun;
    });
  };

  useEffect(() => {
    document.body.classList.toggle("body-dark", isSun);
    document.body.classList.toggle("body-color", !isSun);
  }, [isSun]);

  return (
    
    <div
      className={`flex z-2 w-full justify-between items-center tracking-normal h-18 pl-2 pr-2 md:pl-4 md:pr-4 lg:pl-6 lg:pr-6 text-white border-white ${
        isSun ? "bg-black" : "bg-white"
      }`}
      id="navbar"
    > 
    {/* <p className="text-black">Cookie Value: {cookieValue}</p> */}
     
      <img
        className=" h-14 mr-3 w-auto max-w-full max-h-full md:max-h-18 ml-0"
        src={logo}
        alt="logo"
        onClick={gotoHome}
      />
      <h1
        className="tracking-wide sm:text-lg w-full text-base leading-tight font-black font-montserrat md:text-xl"
        id="navbar"
      >
        Rajkamal Barscan Systems Pvt Ltd
      </h1>
      <ul className="hidden md:flex">
      <li
          className={`p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            to="/"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/" ? "!text-[#00df9a]" : ""
            }`}
          >
            Home
          </Link>
        </li>
        <li
          className={`p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/about" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            to="/about"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/about" ? "!text-[#00df9a]" : ""
            }`}
          >
            About
          </Link>
        </li>
        <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/Solutions" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            to="/Solutions"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/Solutions" ? "!text-[#00df9a]" : ""
            }`}
          >
            Solutions
          </Link>
        </li>
        <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/Products" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <Link
            to="/Products"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/Products" ? "!text-[#00df9a]" : ""
            }`}
          >
            Products
          </Link>
          {/* {showDropdown && <DropdownMenu />} */}
        </li>
        <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/Products" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <Link
            to="/Labels"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/Labels" ? "!text-[#00df9a]" : ""
            }`}
          >
            Labels
          </Link>
        </li>
        <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/Solutions" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            to="/techSupport"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/techSupport" ? "!text-[#00df9a]" : ""
            }`}
          >
            Tech Support
          </Link>
        </li>
        <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/Career" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            to="/Career"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/Carrer" ? "!text-[#00df9a]" : ""
            }`}
          >
            Careers
          </Link>
        </li>
        <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/caseStudy" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            to="/caseStudy"
            id="navbar"
            className={`font-poppins inline-block whitespace-nowrap  hover:text-[#57b846] font-semibold cursor-pointer ${
              location.pathname === "/caseStudy" ? "!text-[#00df9a]" : ""
            }`}
          >
            Case Study
          </Link>
        </li>
        {/* <li
          className={`font-poppins p-3 hover:text-[#57b846] font-semibold cursor-pointer ${
            location.pathname === "/Contact" ? "text-[#00df9a]" : ""
          }`}
          id="navbar"
        >
          <Link
            className={`text-[#00df9a] ${
              location.pathname === "/Contact" ? "!text-[#00df9a]" : ""
            }`}
            to="/Contact"
            id="navbar"
          >
            Contact
          </Link>
        </li> */}
        <li
          className="font-montserrat p-3 hover:text-green-400 hover:cursor-pointer "
          onClick={handleMode}
        >
          {isSun ? (
            <FaSun className="text-white" />
          ) : (
            <FaMoon className="text-black" />
          )}
        </li>
      </ul>
      <div
        onClick={handleNav}
        className={`font-montserrat block md:hidden hover:cursor-pointer z-50 ${
          isSun ? "text-white" : "text-black"
        }`}
      >
        {console.log("This is IsSun Value", isSun)}
        {nav ? (
          <AiOutlineClose
            size={20}
            className={isSun ? "text-white" : "text-black"}
          />
        ) : (
          <AiOutlineMenu
            size={20}
            className={isSun ? "text-white" : "text-black"}
          />
        )}
      </div>
      <ul
        className={
          nav
            ? " z-20 fixed right-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500 "
            : "ease-in-out duration-500 fixed right-[-100%] "
        }
        ref={navRef}
      >
        <div className="flex items-center justify-between pr-3.5 ">
          <img
            className="  max-w-[40%] h-auto mx-auto sm:mx-2 my-3.5 md:mx-4 lg:mx-6"
            src={logo}
            alt="logo"
          />
        </div>
        <li
          className="font-montserrat p-4 border-b border-gray-600 "
          onClick={handleNav}
        >
          <Link to="/" className="text-black hover:text-[#00df9a] font-poppins">
            Home
          </Link>
        </li>
        <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/caseStudy" className="text-black hover:text-[#00df9a] font-poppins">
            Case Study
          </Link>
        </li>
        <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/about" className="text-black hover:text-[#00df9a] font-poppins">
            About
          </Link>
        </li>
        <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/products" className="text-black hover:text-[#00df9a] font-poppins">
            Products
          </Link>
        </li>
        <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/Labels" className="text-black hover:text-[#00df9a] font-poppins">
            Labels
          </Link>
        </li>
        <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/techSupport" className="text-black hover:text-[#00df9a] font-poppins">
            TechSupport
          </Link>
        </li>
        <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/Solutions" className="text-black hover:text-[#00df9a] font-poppins">
            Solutions
          </Link>
        </li>
        {/* <li
          className="font-montserrat p-4 border-b border-gray-600"
          onClick={handleNav}
        >
          <Link to="/Contact" className="text-black hover:text-[#00df9a] font-poppins">
            Contact
          </Link>
        </li> */}
      </ul>
      {/* Right Drawer Component */}
      {/* <ul
        className={
          nav
            ? "z-20 fixed right-0 top-0 w-[37%] h-full border-r border-r-gray-900 bg-[#000300] text-white ease-in-out duration-500  font-sans"
            : "ease-in-out duration-500 fixed right-[-250%]"
        }
      >
        <div className="flex justify-end pr-4 pt-4">
          <AiOutlineClose
            size={20}
            className="hover:cursor-pointer"
            onClick={handleNav}
          />
        </div>
        <div className="px-5" onClick={handleNav}>
          <img className="w-20 h-auto" src={logo} alt="logo" />
          <h1 className="w-full text-3xl font-bold font-serif">
            Rajkamal Barscan Systems Pvt. Ltd
          </h1>
        </div>

        <div />
        <br />

        <h2 className="mx-4 my-3 leading-tight font-bold font-serif text-[#00df9a]">
          Contact us
        </h2>
        <br />
        <i className="inline ">
          <a href="mailto:sales@rajkamalbarscan.com">
            <i
              className="fa-solid fa-envelope text-2xl mx-4 my-1 flex items-center"
              aria-hidden="true"
            >
              <span className="pl-3 text-lg font-sans">
                sales@rajkamalbarscan.com
              </span>
            </i>
          </a>
          <br />
          <a href="tel:+912242147000">
            <i
              className="fa-solid fa-phone mx-4 my-3 text-2xl flex items-center"
              aria-hidden="true"
            >
              <span className="pl-2 text-xl font-sans">+91-2242147000</span>
            </i>
          </a>
          <br />
          <a
            href="https://google.com/maps/place/Rajkamal+Barscan+Systems+Pvt.+Ltd/@19.1192722,72.8593686,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7c82ffb046311:0xc13748063e112765!8m2!3d19.1192671!4d72.8619489!16s%2Fg%2F1tf5j2_k?entry=ttu"
            target="blank"
          >
            <div className="flex items-center">
              <i
                className="fa-solid fa-map-marker mx-4 my-3 text-2xl"
                aria-hidden="true"
              ></i>
              <p className=" text-xl hover:text-[#00df9a] font-sans">
                A 36, Floor-first Floor, Giriraj Industrial Estate, Mahakali
                Caves Road, Mumbai- 400093, Maharashtra
              </p>
            </div>
          </a>
          <br />
          <p className=" text-sm mx-3 font-sans ">
            RAJKAMAL is united by a common goal: To simplify business through
            technology which helps our customers achieve exponential growth in
            the industry
          </p>

          <div className="col-md-12 mt-10 mx-2">
            <ul className="social-network social-circle ">
              <li>
                <a
                  href="https://www.facebook.com/rajkamalbarscans/?ref=embed_page"
                  target="_blank"
                  className="icoFacebook"
                  title="Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/rajkamal-barscan-systems-pvt.-ltd/"
                  target="_blank"
                  className="icoTwitter"
                  title="Linkedin"
                >
                  <i className="fab fa-linkedin "></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@Rajkamalbarscan"
                  target="_blank"
                  className="icoGoogle"
                  title="Youtube"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </i>
      </ul> */}
    </div>
  );
};

export default Navbar;
