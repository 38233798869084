import React, { memo, useEffect, useState } from "react";
import axios from "axios";

const Solutions = () => {
  const [test, setTest] = useState([]);
  
  useEffect(() => {

    // const isCache=localStorage.getItem("Solutions");
    // if(isCache) {
    //   setTest(JSON.parse(isCache));
    //   return
    // }

    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/solutions/getsolutions")
      .then((res) => {
        setTest(...test, res.data.response);
        console.log(res.data.response);
        // localStorage.setItem("Solutions",JSON.stringify(res.data.response));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="w-full  pt-8 px-4 relative pb-10">
        <div
          className="banner-image absolute top-0 left-0 w-full h-64 md:h-80 lg:h-96 bg-cover bg-center "
          style={{
            backgroundImage: `url('https://t4.ftcdn.net/jpg/03/97/92/89/240_F_397928902_8TVmZ7rpxjbL1H7It2QFExPUawiptA0r.jpg')`,
          }}
        ></div>
        <h1 className="my-5 text-4xl font-extrabold leading-none tracking-tight text-center text-white md:text-5xl lg:text-6xl dark:text-white relative z-1">
          <span className="text-white"
            dangerouslySetInnerHTML={{
              __html: '<h1 style="font-weight: 700;text-white">Solutions</h1>',
            }}
          />
        </h1>

        <p className="mb-0 text-lg font-normal text-center text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html:
                '<span style="letter-spacing: 0.1em ;color:white">Our Comprehensive Range of Software Solutions</span>',
            }}
          />
        </p>
      </div>
      <div className="w-full pt-48 px-5 flex flex-col">
  {test &&
    test.map((testItem, index) => (
      <div className="flex flex-col md:flex-row items-center my-2" key={index}>
        <div className="md:w-2/3 md:mr-8">
          <i className="font-extrabold text-xl" id="solutions">{testItem.title}:-</i>
          <p>
            <span dangerouslySetInnerHTML={{ __html: testItem.description }} className="tracking-wide" id="solutions" />
          </p>
        </div>
        <img className="my-2 md:my-0 md:w-1/3 h-auto z-10" src={testItem.image} alt={testItem.title} />
      </div>
    ))}
</div>
    </>
  );
};

export default memo(Solutions);

{
  /* <div className="w-full bg-white pt-16 px-4 relative">
        <div
          className="banner-image absolute top-0 left-0 w-full h-64 md:h-80 lg:h-96 bg-cover bg-center my-4"
          style={{
            backgroundImage: `url('https://t4.ftcdn.net/jpg/03/97/92/89/240_F_397928902_8TVmZ7rpxjbL1H7It2QFExPUawiptA0r.jpg')`,
          }}
        ></div>
        <h1 className="my-5 text-4xl font-extrabold leading-none tracking-tight text-center text-white md:text-5xl lg:text-6xl dark:text-white relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html: '<h1 style="font-weight: 700;text-white">Solutions</h1>',
            }}
          />
        </h1>
        <p className="mb-0 text-lg font-normal text-center text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html:
                '<span style="letter-spacing: 0.1em ;color:white">Our Comprehensive Range of Software Solutions</span>',
            }}
          />
        </p>
      </div>
      <div className="w-full bg-white pt-48 px-5 flex flex-col  ">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 md:mr-8">
            <i className="font-extrabold text-xl">WareHouse Management System:-</i>
            <p>
              <span className="tracking-wide">
                Our comprehensive solution suite empowers businesses to achieve
                efficient plant management, streamline operations, and enhance
                productivity. With Role-Based Access Control (RBAC) based user
                management, we ensure secure and customized access for your
                team. Our inventory management system covers Material Master,
                Raw Material (RM), Work-in-Progress (WIP), and Finished Goods
                (FG) tracking, including Rack and Pallet management for seamless
                logistics. In the realm of production management, our solution
                excels with a Bill of Materials (BOM) master, issue tracking,
                and a versatile process rule engine. We provide end-to-end
                control with barcode, QR code, and RFID code management,
                meticulously mapped to Material Master data. For complete
                transparency, our system logs sales and goods transfers,
                ensuring real-time insights. Moreover, our integration
                capabilities span across accounting and offer a smooth reverse
                integration with ERP systems like SAP and Infor. These robust
                features collectively empower your business to optimize its
                operations and drive growth, making us your ideal partner for
                comprehensive software solutions.
              </span>
            </p>
          </div>
          <img
            className="banner-image my-5 md:my-0 md:w-1/3"
            src="https://dearsystems.com/wp-content/uploads/2022/02/wms-implementation-circle.jpeg"
            alt="Warehouse Management System"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 md:mr-8">
            <i className="font-extrabold text-xl">Asset Tracking:-</i>
            <p>
              <span
                className="tracking-wide"
                dangerouslySetInnerHTML={{
                  __html:
                    "<span>Our innovative solution offers a robust platform for asset management, providing your organization with a holistic approach to efficiently handle your valuable assets. With our user-friendly interface, asset creation becomes a breeze, allowing you to establish a flexible category, class, and type structure for master creation. Our comprehensive user management system ensures secure and tailored access for your team, granting them the right permissions. You can seamlessly track, transfer, and assign assets, ensuring complete control and accountability. Stay on top of maintenance with automated notifications for AMC and insurance tracking, reducing downtime and enhancing cost-effectiveness. Furthermore, our vendor master functionality facilitates smooth collaboration with your suppliers. Our solution's integration capabilities extend to various systems, including ERP, SMS, and email, offering you a seamless flow of information and real-time updates. Whether you're at your desk or on the move, our web and Android app ensures constant connectivity, making asset management effortless and adaptable to your dynamic business needs. With our solution, you're equipped to take your asset management to the next level, enhancing efficiency, transparency, and overall productivity.<span/>",
                }}
              />
            </p>
          </div>
          <img
            className="banner-image my-5 md:my-0 md:w-1/3"
            src="https://bestbarcodeworld.com/wp-content/uploads/2020/02/asset-tracking.png"
          />
        </div>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 md:mr-8">
            <i className="font-extrabold text-xl">Work Perit Management:-</i>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "<span>Our comprehensive solution is tailored to address your business's need for efficient and secure user management with Role-Based Access Control (RBAC) at its core, ensuring that your team has the right access levels. With our powerful permit management system, you can easily create, track, and manage permit forms, even using mobile apps for on-the-go convenience. Additionally, our PSI workflow feature empowers you to generate PSI audits, whether on an ad-hoc basis or as part of a scheduled routine, enhancing safety and compliance. The ability to create custom approval flows for permit and OSI approvals, as well as non-compliance scenarios, streamlines your operations, while real-time notifications keep all stakeholders informed and engaged. Furthermore, our solution effortlessly integrates with other systems, allowing you to access asset and vendor details seamlessly. With this all-encompassing solution, you can enhance productivity, compliance, and overall operational efficiency, ensuring your business is always one step ahead in a dynamic and ever-evolving landscape.<span/>",
                }}
              />
            </p>
          </div>
          <img
            className="banner-image my-5 md:my-0 md:w-1/3"
            src="https://www.complyflow.com/images/p/e/r/m/i/permit-to-work-software-e62c35a3.png"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 md:mr-8">
            <i className="font-extrabold text-xl">Track And Trace:-</i>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "<span>Our Visitor Management System revolutionizes the gate entry process, delivering a seamless, digitized experience for your premises. With easy user sign-up, visitors can access your facilities effortlessly, and our solution is adaptable, whether you choose to deploy it alongside your existing systems or as a standalone system. Streamlining approvals is a core feature, with mobile access ensuring quick and efficient approvals on the go. For heightened security, we offer confirmation through unique codes and secure PIN entry options. Communication with visitors is made simple through SMS and email, with the added possibility of seamless WhatsApp integration. Our system extends beyond visitor management, featuring robust Role-Based Access Control (RBAC) user management, secure PIN-based visitor entry, and the ability to create dynamic forms. Approval flows are efficiently managed through a hierarchical structure, and our independent front-end makes integration with existing websites a breeze. Real-time access to reports and approvals, coupled with mobile connectivity to cloud-enabled servers, guarantees that your operations are agile and efficient, with all the information you need right at your fingertips. With this versatile solution, you can enhance security, streamline visitor management, and ensure a convenient and adaptable user experience tailored to your unique needs.<span/>",
                }}
              />
            </p>
          </div>
          <img
            className="banner-image my-5 md:my-0 md:w-1/3"
            src="https://img.freepik.com/free-vector/tracking-delivery-package-smartphone-application-delivery-point-validation-delivery-driver-app-independent-courier-concept-pinkish-coral-bluevector-isolated-illustration_335657-1784.jpg?size=626&ext=jpg&ga=GA1.1.1826414947.1699228800&semt=ais"
          />
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-2/3 md:mr-8">
            <i className="font-extrabold text-xl">Visitor Management:-</i>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "<span>Our cutting-edge Visitor Management System redefines and simplifies the gate entry process for your premises. It offers a seamless and digital experience, making it easy for visitors to sign up before arriving at your location. Our solution can be seamlessly integrated with your existing systems or operated as a standalone system. With an emphasis on user-friendliness, approvals become a breeze, and our mobile platform enables on-the-go access for approvals. For enhanced security, we offer confirmation through codes and secure PIN entry options. Communication with visitors is made effortless, utilizing SMS and email, and for even more convenience, we offer WhatsApp integration. Beyond visitor management, our system also features Role-Based Access Control (RBAC) user management, secure PIN-based visitor entry, and dynamic form creation, enhancing your overall security and control. Approval flows are streamlined through a hierarchical structure, and an independent front-end allows for easy integration with existing websites. With our versatile solution, you can elevate your gate entry process, ensuring a secure and efficient experience for all visitors and stakeholders, while also providing an adaptable and user-friendly interface that suits your unique needs.<span/>",
                }}
              />
            </p>
          </div>
          <img
            className="banner-image my-5 md:my-0 md:w-1/3"
            src="https://prospace.io/img/vms-infographic.png"
          />
        </div>
        
      </div>
    </> */
}
