import React from "react";

const ReviewsDescription = () => {
  
  const data = JSON.parse(localStorage.getItem("caseStudy"));

  function removeHtmlTagsFromObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/<[^>]*>/g, '');
      }
    }
    return obj;
  }

  removeHtmlTagsFromObject(data);
  
  return (
    <>
      <div className=" w-full  pt-16 px-4 flex flex-col items-center">
        {/* <h1 className="my-4  font-bold text-3xl  leading-none tracking-tight text-center text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          <span dangerouslySetInnerHTML={{ __html: data.title }} />
        </h1> */}
        <h1 className=" my-2 text-4xl font-extrabold leading-none tracking-tight text-center  md:text-5xl lg:text-6xl dark:text-white relative z-10">
        <span dangerouslySetInnerHTML={{ __html: data.title }} style={{ fontWeight: 800 }} id="case_study"/>
        </h1>
        {/* <p className="mb-0 text-lg font-normal text-center text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
        <span dangerouslySetInnerHTML={{ __html: data.sub_title}} />
        </p> */}
      </div>
      <div className="w-full  py-16 px-5 flex flex-col  ">
        <h6 className="font-extrabold text-xl">Short Summary:-</h6>

        <p> <span className="font-poppins" dangerouslySetInnerHTML={{ __html: data.summary}} id="case_study"/></p>
        <br />
        <h6 className="font-extrabold text-xl">Challenges and Solutions:-</h6>
        <p> <span className="font-poppins" dangerouslySetInnerHTML={{ __html: data.des1 }} id="case_study"/></p>
        <br />

        <h6 className="font-extrabold text-xl">
        The Journey:-
        </h6>
        <p> <span className="font-poppins" dangerouslySetInnerHTML={{ __html: data.des2}} id="case_study"/></p>
        <br />

        <h6 className="font-extrabold text-xl">Success Story</h6>
        <p> <span className="font-poppins" dangerouslySetInnerHTML={{ __html: data.des3 }} id="case_study"/></p>
        <br />

        <h6 className="font-extrabold text-xl">Key Highlights :-</h6>
        <p> <span className="font-poppins" dangerouslySetInnerHTML={{ __html: data.des4 }} id="case_study"/></p>
        <br/>

        <h6 className="font-extrabold text-xl">Results :-</h6>
        <p> <span className="font-poppins" dangerouslySetInnerHTML={{ __html: data.results }} id="case_study"/></p>
      </div>
    </>
  );
};

export default ReviewsDescription;
