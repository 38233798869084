import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./css/main.css";
import "./css/util.css";
import img from "./images/bg-01.jpg";

const TechAuthenticate = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ password: "", email: "" });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [name]: value };
      console.log("values", updatedFormData);
      return updatedFormData;
    });
  };

  const handleLogin = (data) => {
    console.log("This is data", data[0]);
    localStorage.setItem("login", JSON.stringify(data[0]));
    
    navigate("/techSupport");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/authenticate/userlogin", {
        email_value: formData.email,
        password_val: formData.password,
      })
      .then((res) => {
        if (res.data.status === true && res.data.response.length > 0) {
          const userRole = res.data.response[0].role;
          if (userRole) {
            console.log("Role from API:", userRole);
          }
          alert("login success");
          handleLogin(res.data.response);
        } else {
          alert("Please check your credentials");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <html lang="en">
        <title>Login V15</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" type="text/css" href="css/util.css" />
        <link rel="stylesheet" type="text/css" href="css/main.css" />

        <body>
          <div className="limiter">
            <div className="container-login100">
              <div className="wrap-login100">
                <div
                  className="login100-form-title"
                  style={{ backgroundImage: `url(${img})` }}
                >
                  <span className="login100-form-title-1">Sign In</span>
                </div>

                <form
                  className="login100-form validate-form"
                  id="signinForm"
                  onSubmit={handleSubmit}
                >
                  <div
                    className="wrap-input100 validate-input m-b-26"
                    data-validate="Email is required"
                  >
                    <span className="label-input100">Email</span>
                    <input
                      className="input100"
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Enter email"
                      onChange={handleInputChange}
                    />
                    <span className="focus-input100"></span>
                  </div>

                  <div
                    className="wrap-input100 validate-input m-b-18"
                    data-validate="Password is required"
                  >
                    <span className="label-input100">Password</span>
                    <input
                      className="input100"
                      type="password"
                      name="password"
                      value={formData.password}
                      placeholder="Enter password"
                      onChange={handleInputChange}
                    />
                    <span className="focus-input100"></span>
                  </div>
                  <div className="container-login100-form-btn mt-4 flex justify-center">
                    <button className="login100-form-btn ">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </body>
      </html>
    </>
  );
};

export default TechAuthenticate;
