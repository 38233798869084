import React, { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const ProductSlider = () => {
  
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);

  const [category, setCategory] = useState([]);

  const [brand, setBrand] = useState([]);

  const [test, setTest] = useState([]);

  const [data, setData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const navRef = useRef(null);

  const handleToogle = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // const categorySelected = data;
    const cacheKey = `${selectedCategory}_${selectedBrand}_products`;

    // Check if data is already cached
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      setTest(JSON.parse(cachedData));
      return;
    }

    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/products/getproducts", {
        categorySelected: category?.[selectedCategory]?.title || null,
        brandSelected: brand?.[selectedBrand]?.title || null,
      })
      .then((res) => {
        console.log("this is products Api", res.data.response);
        setTest(res.data.response);
        // Cache the data
        localStorage.setItem(cacheKey, JSON.stringify(res.data.response));
      })
      .catch((err) => console.log(err));
  }, [selectedBrand, selectedCategory]);

  useEffect(() => {
    const cachedBrands = localStorage.getItem("brandsData");
    console.log("Cached Brands:", cachedBrands); // Check the value of cachedBrands

    if (cachedBrands) {
      setBrand(JSON.parse(cachedBrands));
      console.log("Cache Working");
      return; // Exit early if cached data is available
    }

    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/products/getbrands")
      .then((res) => {
        console.log("Cache not working");
        console.log("This are all the brands", res.data.response);
        setBrand(res.data.response);
        localStorage.setItem("brandsData", JSON.stringify(res.data.response));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const cacheCategory = localStorage.getItem("categoryData");
    if (cacheCategory) {
      setCategory(JSON.parse(cacheCategory));
      return;
    }
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/category/getcategory")
      .then((res) => {
        console.log("This are all the brands", res.data.response);
        setCategory(res.data.response);
        localStorage.setItem("categoryData", JSON.stringify(res.data.response));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClick = async (item) => {
    if (localStorage.getItem("productSelected")) {
      localStorage.removeItem("productSelected");
    }
    localStorage.setItem("productSelected", JSON.stringify(item));

    await new Promise((resolve) => {
      resolve();
    });
    console.log(item);
    navigate("/productdescription");
  };

  const handleBrandChange = (newIndex) => {
    // Create a copy to avoid mutating the original state
    const updatedSelectedBrand = [...selectedBrand];

    // If the clicked brand is already selected, deselect it (remove from array)
    if (updatedSelectedBrand.includes(newIndex)) {
      const indexToRemove = updatedSelectedBrand.indexOf(newIndex);
      updatedSelectedBrand.splice(indexToRemove, 1);
    } else {
      // If not already selected, add it (assuming single selection)
      if (updatedSelectedBrand.length < 1) {
        // Allow only one brand
        updatedSelectedBrand.push(newIndex);
      }
    }

    setSelectedBrand(updatedSelectedBrand);

    const checkboxElement = document.getElementById(`brand-${newIndex}`);
    if (checkboxElement) {
      checkboxElement.checked = updatedSelectedBrand.includes(newIndex);
    }

    console.log(selectedBrand, selectedCategory);
  };

  const handleCategoryChange = (newIndex) => {
    const updatedSelectedBrand = [...selectedCategory];

    if (updatedSelectedBrand.includes(newIndex)) {
      const indexToRemove = updatedSelectedBrand.indexOf(newIndex);
      updatedSelectedBrand.splice(indexToRemove, 1);
    } else {
      if (updatedSelectedBrand.length < 1) {
        updatedSelectedBrand.push(newIndex);
      }
    }

    setSelectedCategory(updatedSelectedBrand);

    const checkboxElement = document.getElementById(`category-${newIndex}`);
    if (checkboxElement) {
      checkboxElement.checked = updatedSelectedBrand.includes(newIndex);
    }
  };

  return (
    <>
      <div className="mt-4">
        <h2 className=" text-center ">Explore Our Products</h2>
      </div>
      <div className=" mx-2 sm:block md:hidden lg:hidden xl:hidden hover:text-[#00df9a]">
        <i
          id="filter"
          className="fas fa-filter my-3 mx-3 text-2xl"
          onClick={handleToogle}
        ></i>
      </div>
      <form>
        <ul
          className={
            isOpen
              ? "z-10 fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500"
              : "ease-in-out duration-500 fixed right-[-100%] hidden sm:block"
          }
          ref={navRef}
        >
          <div className="flex items-center font-poppins">
            <h5
              id="drawer-label"
              className="inline-flex items-center my-5 mx-5 font-poppins text-base font-semibold text-black uppercase dark:text-gray-400"
            >
              Apply filters
            </h5>
            <button
              type="button"
              data-drawer-dismiss="drawer-example"
              aria-controls="drawer-example"
              className="  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5 "
                fill="currentColor"
                viewBox="0 0 20 20"
                onClick={handleToogle}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          </div>

          <div className="flex flex-col justify-between flex-1">
            <div className="space-y-6">
              <div className="space-y-2">
                <h6 className="text-base font-medium text-black dark:text-white">
                  Categories
                </h6>

                {category.map((item, index) => (
                  <div key={index} className="flex items-center">
                    <input
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          type="checkbox"
                          checked={selectedCategory.includes(index)}
                          onChange={() => handleCategoryChange(index)}
                        />
                    <label
                      htmlFor={`desktop-${index}`}
                      className="ml-2 text-sm font-medium text-black dark:text-gray-300"
                    >
                      {item.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between flex-1">
            <div className="space-y-6">
              <div className="space-y-2">
                <h6 className="text-base text-poppins my-3 font-medium text-black dark:text-white">
                  Brands
                </h6>

                {brand.map((item, index) => (
                  <div key={`brand-${index}`} className="flex items-center">
                    <input
                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          type="checkbox"
                          checked={selectedBrand.includes(index)}
                          onChange={() => handleBrandChange(index)}
                        />
                    <label
                      htmlFor={`deskt op-${index}`}
                      className="ml-2 text-sm font-medium text-black dark:text-gray-300"
                    >
                      {item.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="bottom-0 left-0 flex justify-center w-full pb-4 mt-6 space-x-4 md:px-4 md:absolute">
              <button
                type="reset"
                className=" mx-2 w-full px-5 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={() => {
                  setSelectedBrand([]);
                  setSelectedCategory([]);
                }}
              >
                Clear all
              </button>
            </div>
          </div>
        </ul>
      </form>

      <div className="px-4 sm:px-6 lg:px-8 pt-10 pb-10 sm:pb-14 lg:pb-18 xl:pb-22 ">
        <div className="d-sm-flex align-items-sm-center  clear">
          <div className="text-muted filter-label">
            {selectedCategory.length > 0 || selectedBrand.length > 0 ? (
              <h6>Applied Filters:</h6>
            ) : null}
          </div>
          {category &&
            selectedCategory.map((index) => (
              <div
                key={`category-${index}`}
                className="green-label font-weight-bold p-0 px-1 mx-sm-1 mx-0"
              >
                {category[index]?.title || "No title"}
                <span
                  className="px-1 close"
                  onClick={() => handleCategoryChange(index)}
                >
                  &times;
                </span>
              </div>
            ))}

          {brand &&
            selectedBrand.map((index) => (
              <div
                key={`brand-${index}`}
                className="green-label font-weight-bold p-0 px-1 mx-sm-1 mx-0"
              >
                {brand[index]?.title || "No title"}
                <span
                  className="px-1 close"
                  onClick={() => handleBrandChange(index)}
                >
                  &times;
                </span>
              </div>
            ))}
        </div>
        <div className="filters">
          <button
            className="btn btn-success"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-filter"
            aria-expanded="true"
            aria-controls="mobile-filter"
          >
            Filter
            <span className="px-1 fas fa-filter"></span>
          </button>
        </div>
        <div className="content py-md-0 py-3">
          <section id="sidebar" className="hidden sm:block">
            <div className="text-center"></div>

            <div className="py-3">
              <h5 className="font-weight-bold">Category</h5>

              <htmlForm className="brand">
                {category &&
                  category.length > 0 &&
                  category.map((item, index) => (
                    <div
                      key={index}
                      className="htmlForm-inline d-flex align-items-center py-1"
                    >
                      <label className="tick">
                        {item.title}
                        <input
                          type="checkbox"
                          checked={selectedCategory.includes(index)}
                          onChange={() => handleCategoryChange(index)}
                        />

                        <span className="check"></span>
                      </label>
                    </div>
                  ))}
              </htmlForm>
            </div>
            <div className="py-3">
              <h5 className="font-weight-bold">Brands</h5>
              <htmlForm className="brand">
                {brand.length > 0 &&
                  brand.map((item, index) => (
                    <div
                      key={index}
                      className="htmlForm-inline d-flex align-items-center py-1"
                    >
                      <label className="tick">
                        {item.title}
                        <input
                          type="checkbox"
                          checked={selectedBrand.includes(index)}
                          onChange={() => handleBrandChange(index)}
                        />
                        <span className="check"></span>
                      </label>
                    </div>
                  ))}
              </htmlForm>
            </div>
          </section>
          <div className="container ">
            <div className="flex-1 ">
              <div className="row">
                {test.length > 0 ? (
                  test.map((item, index) => (
                    <div
                      key={index}
                      className="col-md-3 hover:scale-105 duration-300"
                      onClick={() => handleClick(item)}
                    >
                      <div className="card">
                        <img
                          src={item.img}
                          className="card-img-top"
                          alt="Product Image"
                          width={300}
                          height={300}
                        />
                        <div className="card-body">
                          <h5 className="card-title font-poppins text-black">
                            {item.title}
                          </h5>
                          
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <p>No data available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductSlider;
