import React, { useState, useEffect } from "react";
import axios from "axios";

const DropdownMenu = () => {
  // const data = ["Hardware", "Consumables"];
  const [data, setData] = useState([]);

  useEffect(() => {
    // const categorySelected = data;
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/category/getcategory")
      .then((res) => {
        setData(res.data.response);
        // console.log(res.data.response);
      })
      .catch((err) => console.log(err));
  });

  const handleClick = (item) => {
    console.log("Selected category: " + item.title);
    if (localStorage.getItem("category")) {
      localStorage.removeItem("category");
    }
    localStorage.setItem("category", JSON.stringify(item.title));
    window.location.href = "/Products";
  };

  return (
    <div className="sticky inline-block text-center z-10 hover:cursor-pointer">
      <div
        className="absolute pr-4 mt-3 left-1/2 transform -translate-x-1/2 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div className="py-1" role="none">
          {data.map((item, index) => (
            <a
              key={index}
              className="text-gray-700 block px-4 py-2 text-sm hover:text-green-500 whitespace-nowrap" // Apply the whitespace-nowrap class here
              role="menuitem"
              tabIndex={-1}
              id={`menu-item-${index}`}
              onClick={() => handleClick(item)}
            >
              {item.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
