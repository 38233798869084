import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Analytics from "./components/Analytics";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Login from "./components/Login/Login";
import Reg from "./components/Login/Reg";
// import ContactForm from "./components/Contact/Contactform";
// import Team from "./components/Team";
// import Clients from "./components/Clients";
import ProductSlider from "./components/Products";
import ProductDescription from "./components/ProductDescription";
import ScrollToTop from "./components/scrollToTop";
import Carrer from "./components/Carrers";
import Carrer_description_page from "./components/Carrer_description_page";
import ReviewsList from "./components/ReviewsList";
import ReviewsDescription from "./components/ReviewsDescription";
import E_Waste from "./components/E-Waste";
import Quad_img from "./components/Quad_img";
// import Quote from "./components/Quote";
// import Claim from "./components/Claim_page";
// import Terms from "./components/Terms&conditions";
// import Policy from "./components/Policy";
import Demo from "./components/demo";
// import Cards from "./components/Card";
import Error from "./components/Error";
// import { loginContext } from "./Contexts/ThemeContext";
import About from "./components/About";
// import Cart from "./components/Cart";
import Solutions from "./components/Solutions";
// import ProductPagebrands from "./components/ProductPagebrands";
import Contact from "./components/Contact";
import QuotationPage from "./components/QuotationPage";
import Labels from "./components/Labels";
import TechSupport from "./components/TechSupport";
import TechAuthenticate from "./components/New folder/login-form-v15/Login_v15/TechAuthenticate";
import Protected from "./components/Protected";

function App() {
  // const [theme, setTheme] = useState("dark");
  // const [role1, setRole] = useState("");
  // const [formData, setFormData] = useState({
  //   title: "",
  //   subtitle: "",
  //   img: "",
  //   price: "",
  // });
  // console.log("Current URL:", window.location);

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                {" "}
                <Navbar />
                <Hero />
                <Analytics />
                <Demo />
                <Quad_img />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path="/Clients"
            element={
              <>
                <Navbar />
                <Demo />
                <Footer />
              </>
            }
          />
          <Route
            path="/TechAuthenticate"
            element={
              <>
                {" "}
                <Navbar />
                <TechAuthenticate />
                <Footer />
              </>
            }
          />
          <Route
            path="/techSupport"
            element={<Protected Component={TechSupport} />}
          />
          <Route
            path="/Ewaste"
            element={
              <>
                <E_Waste />
              </>
            }
          />
          <Route
            path="/Career"
            element={
              <>
                <Navbar />
                <Carrer />
                <Footer />
              </>
            }
          />
          <Route
            path="/career_description"
            element={
              <>
                <Navbar />
                <Carrer_description_page />
                <Footer />
              </>
            }
          />

          <Route
            path="/contact"
            element={
              <>
                <Navbar />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <React.Fragment>
                <Navbar />
                <About />
                <Footer />
              </React.Fragment>
            }
          />
          <Route
            path="/caseStudy"
            element={
              <>
                <ScrollToTop />
                <Navbar />
                <ReviewsList />
                <Footer />
              </>
            }
          />
          <Route
            path="/Products"
            element={
              <>
                <ScrollToTop />
                <Navbar />
                <ProductSlider />
                <Footer />
              </>
            }
          />
          <Route
            path="/productdescription"
            element={
              <>
                <ProductDescription />
                <Footer />
              </>
            }
          />
          <Route
            path="/quote"
            element={
              <>
                <Navbar />
                {/* <Quote /> */}
                <QuotationPage />
                <Footer />
              </>
            }
          />
          <Route
            path="/Labels"
            element={
              <>
                <ScrollToTop />
                <Navbar />
                <Labels />
                <Footer />
              </>
            }
          />
          <Route
            path="/Solutions"
            element={
              <>
                <Navbar />
                <Solutions />
                <Footer />
              </>
            }
          />
          <Route
            path="/reviews_description"
            element={
              <>
                <Navbar />
                <ReviewsDescription />
                <Footer />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Error />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
