import React from "react";
import styled, { keyframes, css } from "styled-components";
import toi from '../assets/toi.png'
import pvr from '../assets/pvr.png'
import ikea from '../assets/ikea.png'
import lenovo from '../assets/lenovo.png'
import honeywell from '../assets/honeywell.png'
import LT from '../assets/LT.png'
import JandJ from '../assets/JandJ.png'
import air_india from '../assets/airIndia.png'
import wipro from '../assets/wipro.png';

function Demo() {
  const row1 = [ 
    "https://clipart-library.com/img1/753939.png",
    "https://www.freepnglogos.com/uploads/pepsi-png-logo/pepsi-logo-full-hd-pictures-png-logo-1.png",
    "https://www.freepnglogos.com/uploads/pepsi-png-logo/box-pepsi-png-logo-17.png",
    pvr,
    toi,
    wipro
  ];

  const row2 = [
    ikea,
    lenovo,
    JandJ,
    honeywell,
    LT,
    air_india,
  ];

  return (
    <AppContainer>
      <Wrapper>
        <h2 className="font-poppins text-2xl sm:text-3xl md:text-4xl font-bold mb-5 mt-0" id="demo">With Great Outcomes.</h2>
        <span id="about" className="font-poppins text-base sm:text-lg md:text-xl py-2">Unlocking Prosperity: Witness Remarkable Revenue Growth Through Automation Transformation</span>
        <Marquee>
          <MarqueeGroup>
          {row1.map((el, index) => ( 
            <ImageGroup key={`row1-${index}`}>
              <Image src={el} />
            </ImageGroup>
          ))}
          </MarqueeGroup>
          <MarqueeGroup>
          {row1.map((el, index) => ( 
            <ImageGroup key={`row1-${index}`}>
              <Image src={el} />
            </ImageGroup>
          ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
          {row2.map((el, index) => ( 
            <ImageGroup key={`row2-${index}`}>
              <Image src={el} />
            </ImageGroup>
          ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
          {row2.map((el, index) => ( 
            <ImageGroup key={`row2-${index}`}>
              <Image src={el} />
            </ImageGroup>
          ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>
    </AppContainer>
  );
}

export default Demo;

const AppContainer = styled.div`
width: 100%;
min-height: 100vh; /* Default height */
color: #000000;
position: relative;
display: flex;
align-items: center;
justify-content: center;

@media screen and (max-width: 768px) {
  min-height: 80vh; /* Adjusted height for smaller screens */
}

@media screen and (max-width: 480px) {
  min-height: 60vh; /* Further adjusted height for even smaller screens */
}
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
`;


const Text = styled.div`
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #02203c;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Note = styled.div`
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 2rem;
  color: #7c8e9a;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;


const Marquee = styled.div`
  display: flex;
  width: 90vw; /* Adjusted width for responsiveness */
  overflow: hidden;
  user-select: none;
  mask-image: linear-gradient(
    to right,
    hsla(0, 0%, 0%, 0),
    hsla(0, 0%, 0%, 1) 10%,
    hsla(0, 0%, 0%, 1) 90%,
    hsla(0, 0%, 0%, 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
  width: fit-content; /* Adjusted width to fit content */
`;

const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
  width: fit-content; /* Adjusted width to fit content */
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(6rem, 12vw, 15rem); /* Adjusted image group width */
  padding: clamp(0.5rem, 1vw, 1rem); /* Adjusted padding */
`;

const Image = styled.img`
  object-fit: contain;
  max-width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  border-radius: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;