import React from "react";
import earth from "../assets/nature_1.jpg";
import climate from "../assets/sun.png";
import paw from "../assets/paw.png";
import water from "../assets/water.png";
import footer_img from "../assets/footer_img.PNG";
import ScrollToTop from "./scrollToTop";

const E_Waste = () => {
  const scrolldown = () => {
    window.scrollTo(1100, 1100);
  };
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="bg-[#91e8e3] min-h-screen pt-8">
        <div className="w-full py-16 px-4 flex flex-col items-center">
          <h1 className="mb-4 text-4xl font-bold font-serif leading-none tracking-tight text-center text-black md:text-5xl lg:text-6xl dark:text-white">
            E-Waste Management
          </h1>
          <br />
          <div className="w-full max-w-3xl mx-auto">
            <p className="mb-15 text-lg font-normal font-serif text-center text-black lg:text-xl dark:text-gray-400">
              Electronic waste, or e-waste, is a term for electronic products
              that have become unwanted, non-working or obsolete, and have
              essentially reached the end of their useful life.
            </p>
          </div>
          <button
            className="bg-[#0B4E63] text-white border font-serif text-xl w-[200px] rounded-md font-bold my-12 py-3  hover:scale-105 duration-200"
            onClick={scrolldown}
          >
            Learn More
          </button>
          <img src={earth} alt="Earth" className="mx-auto w-50" />
        </div>
      </div>
      <div className=" font-serif ">
        <div className="bg-black  ">
          <div className="w-full py-16 px-4 flex flex-col items-center">
            <br />
            <h6 className="mb-4 text-xl font-normal font-serif leading-none tracking-tight text-center text-white md:text-5xl lg:text-5xl dark:text-white">
              Unlocking a Sustainable Future:
            </h6>
          </div>
          <div className=" max-w-[1240px] mx-auto grid md:grid-cols-3">
            <a
              href="#"
              className="block  max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <img
                className=" w-20 rounded-full"
                src={water}
                alt="Rounded avatar"
              />
              <h5 className=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Fresh Water
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                E-waste pollutes water sources, endangering ecosystems and human
                health, as toxic chemicals seep into fresh water supplies,
                disrupting aquatic life and ecosystems.
              </p>
            </a>
            <a
              href="#"
              className="block  max-w-sm p-6 bg-[#91e8e3] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <img
                className=" w-20 rounded-full"
                src={climate}
                alt="Rounded avatar"
              />
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Climate
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                E-waste emits greenhouse gases, exacerbating climate change by
                polluting the environment and contributing to global warming.
              </p>
            </a>
            <a
              href="#"
              className=" block  max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <img
                className="  w-20 rounded-full"
                src={paw}
                alt="Rounded avatar"
              />
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Wildlife
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                E-waste contaminates habitats, harming wildlife through toxic
                exposure and habitat destruction.
              </p>
            </a>
          </div>
        </div>
        <div className=" bg-black w-full py-4 px-4 flex flex-col items-center">
          <i className=" tracking-widest  my-5 text-lg font-light font-serif leading-none  text-center text-[#91e8e3] md:text-2xl lg:text-2xl dark:text-white">
            Empowering Change Through Responsible E-Waste Management
          </i>
          <p className=" mx-10 mb-4 text-sm font-light font-serif leading-relaxed text-left text-white md:text-base lg:text-lg dark:text-white">
            Electronic waste, often referred to as e-waste, represents a growing
            challenge in today's digital age. It encompasses discarded or
            obsolete electronic devices, from smartphones to laptops, that have
            reached the end of their operational lives. The rise of technology
            consumption has led to a surge in e-waste generation. E-waste is a
            complex mixture of materials, some of which are highly valuable,
            like metals (aluminum, copper, gold, silver) and plastics, while
            others are hazardous, such as lead, mercury, and cadmium. Proper
            management of e-waste is essential to both conserve valuable
            resources and prevent environmental harm. In accordance with the
            E-waste Rule of 2016, responsible recycling and disposal are
            crucial. Companies like Z Enviro Industries Pvt. Ltd. play a vital
            role in collecting, dismantling, and recycling e-waste. They have
            state-of-the-art facilities equipped to handle electronic waste
            safely and responsibly. As consumers, it's essential to do our part
            by choosing authorized recyclers and collection centers for our old
            electronic products. This ensures that valuable materials are
            recovered, and hazardous substances are disposed of correctly. Let's
            embrace a sustainable approach to managing e-waste, protecting our
            environment and conserving valuable resources for future
            generations. Remember the do's and don'ts to contribute to this
            cause effectively. Together, we can make a significant difference in
            addressing the e-waste challenge.
          </p>

          {/* <img
            className="my-5"
            height="30%"
            width="60%"
            src="https://wcm-cdn.wacom.com/-/media/images/e_waste/channelization_of_e_waste.jpg?rev=1facdd2db62841d6a43b0b319603d17a&hash=9754194FA1142E876C3647F233D49410"
            alt="Channelization of E-waste"
          />
          <br /> */}
          <i className=" tracking-widest  mb-4 text-lg font-light font-serif leading-none  text-center text-[#91e8e3] md:text-2xl lg:text-2xl dark:text-white">
            The Do's of E-Waste Disposal"
          </i>
          <br />
          <ul className="list-disc ml-5">
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Always look for information on the catalogue with your product for
              end-of-life equipment handling.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Ensure that only Authorized Recyclers repair and handle your
              electronic products.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Always call our E-waste Authorized Collection Centres/points to
              Dispose products that have reached end-of life.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Always drop your used electronic products, batteries, or any
              accessories when they reach the end of their life at your nearest
              Authorized E-Waste Collection Centres/Points.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed text-white md:text-lg lg:text-xl dark:text-white">
              Always disconnect the battery from the product, and ensure any
              glass surface is protected against breakage.
            </li>
          </ul>
          <br />
          <br />

          <i className=" tracking-widest  mb-4 text-lg font-light font-serif leading-none  text-center text-[#91e8e3] md:text-2xl lg:text-2xl dark:text-white">
            The Don't of E-Waste Disposal"
          </i>
          <br />
          <ul className="list-disc ml-5">
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Don't dispose of electronic waste without checking the product
              catalog for proper end-of-life equipment handling.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Don't let unauthorized individuals or repair services handle your
              electronic products.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Don't dispose of products that have reached end-of-life in
              inappropriate places; always use our E-waste Authorized Collection
              Centres/points.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Don't throw away used electronic products, batteries, or
              accessories in regular trash; take them to your nearest Authorized
              E-Waste Collection Centres/Points.
            </li>
            <li className="mx-40 list-disc mb-2 text-sm font-light font-serif leading-relaxed  text-white md:text-lg lg:text-xl dark:text-white">
              Don't forget to disconnect the battery from the product before
              disposal, and ensure that any glass surfaces are protected against
              breakage.
            </li>
          </ul>
          <br />
          <br />
          <i className="mt-2 tracking-widest  text-xl font-light font-serif leading-none  text-center text-[#91e8e3]  md:text-2xl lg:text-2xl dark:text-white">
            Details of Collection Centre:​
          </i>
        </div>
        <div className="w-full py-2 px-4 flex flex-col items-center bg-black">
          <div className="relative  mx-5 overflow-x-auto    max-w-4xl overflow-y-scroll bg-white  dark:bg-gray-700 dark:border-gray-600 h-96">
            <table className=" mb-5 w-full   text-sm text-left text-black dark:text-gray-400 ">
              <thead className="text-xs text-black uppercase rounded-xl bg-gray-200 dark:bg-gray-700 dark:text-gray-400 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Address
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Rajkamal BarScans Systems Pvt. Ltd
                  </th>
                  <td className="px-6 py-4">
                    A-17, Gala No. 03, Pritesh Complex, Owali Village, Dapode
                    Road, Dist- Thane, Bhiwandi -421302, Maharashtra, India
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Rajkamal Bar Scans Systems Pvt. Ltd
                  </th>
                  <td className="px-6 py-4">
                    #8-2-686/PD/B5, 1st Floor, Prime Dwelling Apartment, Inside
                    Kaman, Banjara Hills , Road No – 12, Hyderabad – 500034,
                    India.
                  </td>
                </tr>
                <tr className="bg-white dark:bg-gray-800">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Rajkamal Bar Scans Systems Pvt. Ltd
                  </th>
                  <td className="px-6 py-4">
                    4th cross N R Road, Near Andhra bank Bangalore-560002,
                    India.
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Rajkamal Bar Scans Systems Pvt. Ltd
                  </th>
                  <td className="px-6 py-4">
                    khasra No. 14/16/1, Budh Vihar, Industrial Area, ​ Mandoli –
                    110093, India.
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    S-Net Freight (India) Pvt. Ltd.
                  </th>
                  <td className="px-6 py-4">
                    PLOT NO.23, 3RD FLOOR, DWARKA SEC-20, NEAR DWARKA SECTOR -9,
                    METRO STATION, UPPER TOYOTA SHOWROOM, NEW DELHI – 110 075,
                    INDIA.
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    S-Net Freight (India) Pvt. Ltd.
                  </th>
                  <td className="px-6 py-4">
                    206, 2ND FLOOR, CENTRE POINT, ANDHERI KURLA ROAD, ANDHERI
                    EAST, J B NAGAR, ​ MUMBAI - 400 059, INDIA.
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    S-Net Freight (India) Pvt. Ltd.
                  </th>
                  <td className="px-6 py-4">
                    UNIT No. 106, LEVEL 1, No 7 PRESTIGE CENTRE POINT CUNNINGHAM
                    ROAD, ​ BENGALURU - 560052, INDIA
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    S-Net Freight (India) Pvt. Ltd.
                  </th>
                  <td className="px-6 py-4">
                    NEW No.: 26 / OLD NO: 13/2, ​ CRESCENT PARK STREET, II
                    FLOOR, T. NAGAR, CHENNAI – 600 017, INDIA
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p className="bg-black flex flex-row items-center justify-center pb-3 mb-0">
          <a href="mailto:warehouse@rajkamalscan.com">
            <i className="text-lg font-serif text-white mx-3 hover:text-[#91e8e3]">
              Email: warehouse@rajkamalscan.com
            </i>
          </a>
          <a href="tel:+9321868705">
            <i className="mx-3 text-lg font-serif text-white">
              Phone: 9321868705
            </i>
          </a>
        </p>
      </div>

      <div className=" bg-black relative w-full overflow-hidden my-0 ">
        <img src={footer_img} className="w-full " alt="Footer" />
        <div className="absolute inset-0 flex flex-col items-center justify-center ">
          <h1 className="mb-9 text-4xl font-semibold font-serif leading-none tracking-tight text-center text-black md:text-5xl lg:text-6xl dark:text-white">
            SAFEGUARD NATURE
          </h1>
          <div className="w-full max-w-5xl mx-auto">
            <p className="mb-15 text-lg font-normal font-serif text-center text-black lg:text-xl dark:text-gray-400">
              Electronic waste, or e-waste, is a term for electronic products
              that have become unwanted, non-working or obsolete, and have
              essentially reached the end of their useful life.
            </p>
          </div>
          <button
            className="bg-[#0B4E63] text-white border font-serif text-xl w-[200px] rounded-md font-bold my-12 py-3  hover:scale-105 duration-200"
            onClick={scrollUp}
          >
            Scroll Up
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default E_Waste;
