import React, { useState, useEffect, useContext } from "react";
import "../components/Login/Login.css";
import axios from "axios";

export default function About() {
  const [data, setData] = useState([]);

  const val = JSON.parse(localStorage.getItem("themePreference"));
  console.log(typeof val);

  useEffect(() => {
    const cachedData = localStorage.getItem("aboutData");
    if (cachedData) {
      setData(JSON.parse(cachedData));
      return;
    }
  
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/about/getabout")
      .then((res) => {
        setData(res.data.response);
        localStorage.setItem("aboutData", JSON.stringify(res.data.response));
      })
      .catch((err) => console.log(err));
  }, []);
  

  return (
    <>
{/* <iframe width="560" height="315" src="https://sps.honeywell.com/us/en/products/productivity/mobile-computers/handheld-computers/eda61k-handheld-computer#overview" frameborder="0" allowfullscreen></iframe> */}

      <div className="w3-container  pb-5" id="about">
      
        {data.length > 0 ? (
          data.map((item, index) => (
            <div key={index}>
              <h2
                className={`pt-24 mt-0 mb-10 text-2xl font-sans font-bold leading-none tracking-tight text-center  md:text-3xl lg:text-4xl sm:text-2xl`}
                id="about"
              >
                {item.title}
              </h2>

              <div className="mt-10 ml-14 mr-14 font-montserrat text-lg leading-7">
                <span
                  className="text-lg font-poppins"
                  style={{ padding: "20px" }}
                  id="about"
                >
                  <div dangerouslySetInnerHTML={{ __html: item.des1 }} />
                </span>
              </div>

              <h2
                className=" my-5 text-xl text-center font-semibold md:text-3xl lg:text-4xl sm:text-2xl"
                id="about"
              >
                Rajkamal Positioning Statement
              </h2>
              <ul className="list-disc  mx-20 text-lg  leading-7">
                {item.des2.split(".").map((item, index) => (
                  <li className="list-disc font-poppins " key={index}>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.trim() }}
                      id="about"
                    />
                  </li>
                ))}
              </ul>
              <h2
                className="text-center text-xl my-5 font-semibold md:text-3xl lg:text-4xl sm:text-2xl"
                id="about"
              >
                Key Achievements: Rajkamal's Major Milestones Over the Years
              </h2>
              <ul className="list-disc  mx-20 text-lg font-poppins leading-7">
                {item.des3.split(".").map((item, index) => (
                  <li className="list-disc " key={index} id="about">
                    <span
                    className="font-poppins"
                      dangerouslySetInnerHTML={{ __html: item.trim() }}
                      id="about"
                    />
                  </li>
                ))}
              </ul>
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 p-4">
                  <h2
                    className="text-xl font-semibold mb-4 items-center ml-28 md:text-3xl lg:text-4xl sm:text-2xl"
                    id="about"
                  >
                    Our Presence
                  </h2>
                  <ul className="list-disc  mx-20 text-lg font-montserrat leading-7">
                    {item.des4.split(".").map((item, index) => (
                      <li className="list-disc font-poppins" key={index}>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.trim() }}
                          id="about"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-full md:w-1/2 p-4">
                  <h2
                    className="text-xl font-semibold mb-4 items-center ml-28 md:text-3xl lg:text-4xl sm:text-2xl"
                    id="about"
                  >
                    Why Choose Us?
                  </h2>
                  <ul className="list-disc mx-20 leading-7 text-lg">
                    {item.des5.split(".").map((item, index) => (
                      <li className="list-disc font-poppins" key={index}>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.trim() }}
                          id="about"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <h2
                className="text-xl font-semibold mb-4 mx-20 md:text-3xl lg:text-4xl sm:text-2xl"
                id="about"
              >
                Company Expertise
              </h2>
              <ul className="list-disc  mx-20 text-lg leading-7">
                {item.des6.split(".").map((item, index) => (
                  <li className="list-disc font-poppins" key={index}>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.trim() }}
                      id="about"
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div className="text-center">
            <span id="about">No data available</span>
          </div>
        )}
      </div>
    </>
  );
}
