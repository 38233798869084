import React, { useEffect, useState, CSSProperties } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const SingleProduct = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1); // Initial quantity

  const handleIncrement = () => {
    setQuantity(Math.max(quantity + 1, 1)); // Ensure minimum quantity is 1
  };

  const handleDecrement = () => {
    setQuantity(Math.max(quantity - 1, 0)); // Ensure minimum quantity is 0
  };

  const [products, setProducts] = useState([]);

  // Function to add a new product
  const addProduct = (newProduct) => {
    // Get the current stored products from localStorage
    const storedProducts = JSON.parse(localStorage.getItem("products")) || [];

    // Add the new product to the stored products array
    const updatedProducts = [...storedProducts, newProduct];

    // Update the state with the updated products array
    setProducts(updatedProducts);

    // Update localStorage with the updated products array
    localStorage.setItem("products", JSON.stringify(updatedProducts));
  };

  const storedProducts = JSON.parse(localStorage.getItem("products"));
  console.log("Stored products local Storage", storedProducts);

  const data = JSON.parse(localStorage.getItem("productSelected"));
  console.log("This is data", data);

  const handleClick = () => {
    navigate("/quote");
  };

  const handleSimilarProductClick = async (item) => {
    if (localStorage.getItem("productSelected")) {
      localStorage.removeItem("productSelected");
    }
    localStorage.setItem("productSelected", JSON.stringify(item));

    await new Promise((resolve) => {
      resolve();
    });

    // navigate("/productdescription");
    window.location.href = "/productdescription";
  };

  const [test, setTest] = useState([]);

  useEffect(() => {
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/products/get_similar_products", {
        categorySelected: data?.category,
        brandSelected: data?.brand,
        id: data?.id,
        type:data?.type
      })
      .then((res) => {
        console.log("this is products Api", res.data.response);
        setTest(res.data.response);
      })
      .catch((err) => console.log(err));
  }, []);

  // const [carousel, setCarousel] = useState(
  //   JSON.parse(data.carousel_images) || []
  // );

  // const [data1,setData]=useState(JSON.parse(carousel) || [])

  // console.log("carousel", typeof data.carousel_images);

  return (
    <React.Fragment>
      <Navbar />
      <div className=" home-section z-0 p-0 m-0">
        <section className="padding-y">
          <div className="container">
            <div className="row">
              <aside className="col-lg-6">
                <article className="gallery-wrap">
                  <Slider {...settings}>
                    <div className=" d-flex  flex-col justify-content-center align-items-center mt-24">
                      <img
                        className="w-full h-full object-contain"
                        src={data ? data.img : "Product Image From Database"}
                        alt="Product_Image From Database"
                        loading="lazy"
                      />
                    </div>
                    <div className=" d-flex  flex-col justify-content-center align-items-center mt-24">
                      <img
                        className="w-full h-full object-contain"
                        src={data ? data.img : "Product Image From Database"}
                        alt="Product_Image From Database"
                        loading="lazy"
                      />
                    </div>
                  </Slider>
                </article>
              </aside>

              <main className="col-lg-6 pt-24">
                <article className="ps-lg-3">
                  <h4 className="title text-dark">
                    <h2 className="font-poppins md:text-4xl sm:text-3xl text-2xl font-bold py-2">
                      {data ? data.title : "Product Title"}
                    </h2>
                  </h4>
                  {/* <div className="rating-wrap my-3 ">
                    <ul className="rating-stars">
                      <li style={{ width: "80%" }} className="stars-active">
                        {" "}
                        <img
                          src="assets/images/misc/stars-active.svg"
                          alt=""
                        />{" "}
                      </li>
                      <li>
                        {" "}
                        <img
                          src="assets/images/misc/starts-disable.svg"
                          alt=""
                        />{" "}
                      </li>
                    </ul>
                  </div> */}

                  <div className="mb-3">
                    {/*<div className="price h5">
                      <span id="about"
                        className="mr-2"
                        dangerouslySetInnerHTML={{
                          __html: data
                            ? data.selling_price
                            : "product_description",
                        }}
                      />
                      <span id="about"
                        dangerouslySetInnerHTML={{
                          __html: data
                            ? data.product_currency + " / per piece "
                            : "product_currency",
                        }}
                      />
                    </div>*/}
                    {/* <span className="text-muted">e</span> */}
                  </div>

                  <p>
                    {data ? (
                      <span id="about" className="font-poppins text-lg ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.product_description || data.label_des,
                          }}
                        ></div>
                      </span>
                    ) : (
                      "product_description"
                    )}
                  </p>
                  <br />

                  <dl className="row">
                    <dt className="col-3 ">Type:</dt>
                    <dd className="col-9 ">
                      {data ? data.category : "category_name"}
                    </dd>

                    <dt className="col-3">Product:</dt>
                    <dd className="col-9">
                      {data ? data.title : "Product_name"}
                    </dd>

                    {/* <dt className="col-3">Material:</dt>
                    <dd className="col-9">Cotton, Jeans </dd> */}

                    {data && ( // Check for data existence only
                      <>
                        {data.brand &&
                          data.brand.length > 0 && ( // Check for brand length only if brand exists
                            <dt className="col-3">Brand:</dt>
                          )}
                        <dd className="col-9">{data.brand || ""}</dd>{" "}
                        {/* Display empty string if brand is null */}
                      </>
                    )}
                  </dl>

                  <hr />
                  <br />
                  <dt className="col-3 mx-5">Quantity:</dt>
                  <div className="quantity-field my-3 mx-5">
                    <button className="" onClick={handleDecrement}>
                      -
                    </button>
                    <input
                      className="rounded-lg border text-center w-5/6"
                      type="number"
                      min="0"
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                    />
                    <button className="" onClick={handleIncrement}>
                      +
                    </button>
                  </div>
                  <a
                    href="#"
                    className="font-poppins mx-5 btn  btn-primary btn-lg mb-10"
                    onClick={handleClick}
                  >
                    {" "}
                    <i className=" mx-1 me-1 font-poppins"></i> Request Quote{" "}
                  </a>
                  {console.log("data.label_des", data.label_des)}
                  {data.label_des ? null : (
                    <a
                      href="#"
                      className="font-poppins mx-5 btn btn-primary btn-lg mb-10"
                      onClick={() => {
                        const currentIndex =
                          localStorage.getItem("currentIndex") || -1;
                        const newIndex = parseInt(currentIndex) + 1;
                        localStorage.setItem("currentIndex", newIndex);
                        addProduct({
                          id: data.id,
                          title: data.title,
                          img: data.img,
                          product_code: data.product_code,
                          brand: data.brand,
                          category: data.category,
                          selling_price: data.selling_price,
                          qty: quantity,
                          quoted_price: null,
                          index: newIndex,
                          col1_description: data.col1_description,
                          col2_description: data.col2_description,
                          col3_description: data.col3_description,
                          col1_title: data.col1_title,
                          col2_title: data.col2_title,
                          col3_title: data.col3_title,
                        });
                      }}
                    >
                      <i className="mx-1 me-1 font-poppins"></i> Add to Quote
                    </a>
                  )}
                </article>
              </main>
            </div>
          </div>
        </section>

        <section className="padding-y bg-light border-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="card">
                  <header className="card-header">
                    <ul className="nav nav-tabs card-header-tabs">
                      <li className="nav-item">
                        <a
                          href="#"
                          data-bs-target="#tab_specs"
                          data-bs-toggle="tab"
                          className="nav-link active"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data ? data.col1_title : "col1_title",
                            }}
                          />
                          {/* {data ? data.col1_title : "tab1"} */}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#"
                          data-bs-target="#tab_warranty"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data ? data.col2_title : "col2_title",
                            }}
                          />
                          {/* {data ? data.col2_title : "tab2"} */}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#"
                          data-bs-target="#tab_shipping"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data ? data.col3_title : "col3_title",
                            }}
                          />
                          {/* {data ? data.col3_title : "tab3"} */}
                        </a>
                      </li>
                    </ul>
                  </header>
                  <div className="tab-content">
                    <article
                      id="tab_specs"
                      className="tab-pane show active card-body"
                    >
                      <br />
                      <p>
                        {data ? (
                          <span className="font-poppins text-lg">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data
                                  ? data.col1_description
                                  : "col1_description",
                              }}
                            />
                          </span>
                        ) : (
                          "col1_description"
                        )}
                      </p>
                      <br />
                      <br />
                      {/* <table className="table border table-hover">
                        <tr>
                          <th>Model:</th>
                          <td>Voyager 1200 Scanner</td>
                        </tr>
                        <tr>
                          <th>Scanner Type:</th>
                          <td>Handheld Laser Scanner</td>
                        </tr>
                        <tr>
                          <th>Scanning Technology:</th>
                          <td>Laser Barcode Scanner</td>
                        </tr>
                        <tr>
                          <th>Scan Rate:</th>
                          <td>100 scans per second</td>
                        </tr>
                        <tr>
                          <th>Connectivity:</th>
                          <td>USB, Bluetooth (optional)</td>
                        </tr>
                      </table> */}
                    </article>
                    <article id="tab_warranty" className="tab-pane card-body">
                      {data ? (
                        <span className="font-poppins text-lg">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data
                                ? data.col2_description
                                : "col2_description",
                            }}
                          />
                          {/* {data.col2_description} */}
                        </span>
                      ) : (
                        "col2_description"
                      )}
                    </article>
                    <article id="tab_shipping" className="tab-pane card-body">
                      {data ? (
                        <span className="font-poppins text-lg">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data
                                ? data.col3_description
                                : "col3_description",
                            }}
                          />
                        </span>
                      ) : (
                        "col3_description"
                      )}
                    </article>
                    {/* <article id="tab_seller" className="tab-pane card-body">
                      Some other tab content or sample information now <br />
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </article> */}
                  </div>
                </div>
              </div>
              {test && test.length > 0 ? (
                <aside className="col-lg-4">
                  <div className="card items-center flex justify-center">
                    <strong className=" items-center flex justify-center my-4 text-2xl font-black ">
                      Similar items
                    </strong>
                    {test &&
                      test.map((item, index) => (
                        <div
                          key={index}
                          className="card-body w-3/5 items-center flex justify-center" 
                        >
                          <article
                            className="itemside mb-3  "
                            onClick={() => handleSimilarProductClick(item)}
                          >
                            <a
                              href=""
                              className="flex justify-center items-center"
                            >
                              <img
                                src={item.img}
                                className="img-md img-thumbnail w-full "
                                alt="Similar_Product"
                              />
                            </a>
                            <div className="info ">
                              <p className="title mb-1 text-lg flex justify-center items-center font-poppins text-black font-medium hover:cursor-pointer ">
                                {" "}
                                {item.title} <br />
                              </p>
                              {/* <strong className="price flex justify-center items-center"> {item.category}</strong> */}
                            </div>
                          </article>
                        </div>
                      ))}
                  </div>
                </aside>
              ) : null}
            </div>

            <br />
            <br />
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default SingleProduct;
