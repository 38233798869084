import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion } from "flowbite-react";
import {
  Label,
  Textarea,
  TextInput,
  Checkbox,
  Alert,
  Radio,
  Avatar,
} from "flowbite-react";
import { HiMail } from "react-icons/hi";
import { Table } from "flowbite-react";
import PhoneInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import axios from "axios";
import * as yup from "yup";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const QuotationPage = () => {
  const storedProducts = JSON.parse(localStorage.getItem("products"));
  console.log("This is stored Products", storedProducts);

  const [cartProduct, setCartProduct] = useState(storedProducts);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    country: "",
    solution: ["N/A"],
    products: cartProduct,
    label_width: "",
    label_height: "",
    label_qty: "",
    label_material: "",
    label_shape: "",
    label_no_of_colors: "",
    label_colors: "",
    label_des: "",
    address: "",
    city: "",
    postalcode: "",
    lamination: "",
    environment: "",
  });

  const [Error, setError] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    country: "",
    solution: [],
    label_width: "",
    label_height: "",
    label_qty: "",
    label_material: "",
    label_shape: "",
    label_no_of_colors: "",
    label_colors: "",
    label_des: "",
    address: "",
    city: "",
    postalcode: "",
    lamination: "",
    environment: "",
  });

  const labelValidationSchema = yup.object({
    label_width: yup
      .number("Label Width should be number")
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? undefined : value
      )
      .positive("Width Cannot be Negative")
      .required("Width is required field"),
    label_height: yup
      .number("Label Height should be number")
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? undefined : value
      )
      .positive("Height Cannot be Negative")
      .required("Height is required field"),
    label_qty: yup
      .number("Quantity should be a Number")
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? undefined : value
      )
      .positive("Quantity Cannot be Negative")
      .required("This is a required field!"),
    label_material: yup
      .string("Label Material should be a string")
      .required("This is a required field!"),
    label_shape: yup
      .string("Label Shape should be a string")
      .required("This is a required field!"),
    label_no_of_colors: yup
      .number("Quantity should be a Number")
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? undefined : value
      )
      .positive("Quantity Cannot be Negative")
      .required("This is a required field!"),
    label_colors: yup
      .string("Label Colours should be a string")
      .required("This is a required field!"),
    lamination: yup.string().required("This is a required field!"),
    environment: yup
      .string()
      .required("Please select an environment (Indoor or Outdoor)"),
    label_des: yup
      .string("")
      .required("Description is required field")
      .min(10, "Description must be at least 10 characters long")
      .max(255, "Description cannot exceed 255 characters"),
  });

  const validationSchema = yup.object({
    name: yup.string().required("Name is a required Field"),
    email: yup
      .string()
      .email("Invalid Email Format")
      .required("Email is a required Field"),
    company: yup
      .string("Company Name must be string")
      .required("Company Name is required field"),
    address: yup
      .string("Address must be string")
      .required("Address is required field"),
    city: yup.string("City should be  a String"),
    postalcode: yup
      .string()
      .matches(/^\d{6}$/, "Invalid postal code format")
      .required("Postal code is required"),
  });

  const handlePhoneNumberChange = (isValid, value, country) => {
    setIsValidPhoneNumber(isValid);
    setFormData({ ...formData, phone: value, country: country });
  };

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const [test, setTest] = useState([]);

  const [labelCategory, setLabelCategory] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const [quantity, setQuantity] = useState(1); // Initial quantity

  const handleInputChange1 = (index, newQty) => {
    const updatedCart = cartProduct.map((item, idx) => {
      if (idx === index) {
        return { ...item, qty: newQty };
      }
      return item;
    });

    localStorage.setItem("products", JSON.stringify(updatedCart));
    setCartProduct(updatedCart);
  };

  const handleIncrement = (index) => {
    const updatedCart = cartProduct.map((item, idx) => {
      if (idx === index) {
        return { ...item, qty: item.qty + 1 };
      }
      return item;
    });

    localStorage.setItem("products", JSON.stringify(updatedCart));
    setCartProduct(updatedCart);
  };

  const handleDecrement = (index) => {
    const updatedCart = cartProduct.map((item, idx) => {
      if (idx === index) {
        return { ...item, qty: Math.max(item.qty - 1, 1) };
      }
      return item;
    });

    localStorage.setItem("products", JSON.stringify(updatedCart));
    setCartProduct(updatedCart);
  };

  // const RemoveProduct = (indexToRemove) => {
  //   const updatedProducts = cartProduct.filter(
  //     (item) => item.index !== indexToRemove
  //   );

  //   const updatedCart = updatedProducts.map((item) => {
  //     if (item.index > indexToRemove) {
  //       return { ...item, index: item.index - 1 };
  //     }
  //     return item;
  //   });

  // localStorage.setItem("products", JSON.stringify(updatedCart));
  // setCartProduct(updatedCart);
  //   const updatedFormData = { ...formData, products: updatedCart };
  //   localStorage.setItem("products", JSON.stringify(updatedCart));
  //   setCartProduct(updatedCart);
  //   setFormData(updatedFormData);
  // };

  const RemoveProduct = (indexToRemove) => {
    const updatedProducts = cartProduct.filter(
      (item) => item.index !== indexToRemove
    );

    const updatedCart = updatedProducts.map((item, index) => ({
      ...item,
      index,
    }));

    const updatedFormData = { ...formData, products: updatedCart };
    localStorage.setItem("products", JSON.stringify(updatedCart));
    setCartProduct(updatedCart);
    setFormData(updatedFormData);
  };

  // const RemoveProduct = (indexToRemove) => {
  //   const updatedProducts = cartProduct.filter(
  //       (item) => item.index !== indexToRemove
  //   );

  //   const updatedCart = updatedProducts.map((item, index) => ({
  //       ...item,
  //       index,
  //   }));

  //   const updatedFormData = { ...formData, products: updatedCart };

  //   localStorage.setItem("products", JSON.stringify(updatedCart));
  //   setCartProduct(updatedCart);
  //   setFormData(updatedFormData);
  // };

  const handleCheckboxChange = (selectedSolution) => {
    setFormData((prevState) => {
      let updatedSolution = [...prevState.solution];

      if (selectedSolution === "N/A") {
        // Toggle "N/A" selection
        if (updatedSolution.includes("N/A")) {
          updatedSolution = updatedSolution.filter((sol) => sol !== "N/A");
        } else {
          updatedSolution = ["N/A"];
        }
      } else {
        // Remove "N/A" if it exists when another solution is selected
        if (updatedSolution.includes("N/A")) {
          updatedSolution = updatedSolution.filter((sol) => sol !== "N/A");
        }

        const existingSolutionIndex = updatedSolution.findIndex(
          (solution) => solution.id === selectedSolution.id
        );

        if (existingSolutionIndex !== -1) {
          // If a solution with the same id exists, remove it
          updatedSolution.splice(existingSolutionIndex, 1);
        } else {
          // If a solution with the same id doesn't exist, add it
          updatedSolution.push(selectedSolution);
        }
      }

      // Update the index for each solution
      updatedSolution = updatedSolution.map((sol, index) => {
        if (sol !== "N/A") {
          return { ...sol, index };
        }
        return sol;
      });

      // If no other solutions are selected, add "N/A" to the list
      if (updatedSolution.length === 0) {
        updatedSolution = ["N/A"];
      }

      console.log("Updated Solution Array", updatedSolution);
      return { ...prevState, solution: updatedSolution };
    });
  };

  const toggleAlert = () => {
    setShowConfirmation(false);
  };

  const handleNavigate = () => {
    navigate("/Products");
  };

  const handleSolutionNavigation = () => {
    navigate("/Solutions");
  };

  const handleClick = async (item) => {
    if (localStorage.getItem("productSelected")) {
      localStorage.removeItem("productSelected");
    }
    localStorage.setItem("productSelected", JSON.stringify(item));

    await new Promise((resolve) => {
      resolve();
    });
    console.log(item);
    navigate("/productdescription");
  };

  const handleSubmit = async (event) => {
    // console.log("FormData", formData);
    event.preventDefault();

    try {
      // Clear previous errors
      // console.log("Submitted", formData);
      setError({});
      const isValid = await validationSchema.validate(formData, {
        abortEarly: false,
      });

      // Check if label data is filled
      const labelDataFilled = await labelValidationSchema.isValid(formData);

      if (
        isValid !== undefined &&
        isValidPhoneNumber === true &&
        formData.phone !== ""
      ) {
        if (
          labelDataFilled ||
          formData.solution != "N/A" ||
          formData.products
        ) {
          console.log("isValid", isValid);
          console.log(" all Conditions are clear Submitted", formData);
          axios
            .post(
              "https://websiteportal.rajkamalbarscan.com/api/v1/quotations/submit_quote",formData
            )
            .then((res) => {
              if (
                res.data.status === true &&
                res.data.response &&
                res.data.response.affectedRows > 0
              ) {
                setShowConfirmation(true);
                setTimeout(() => {
                  setShowConfirmation(false);
                }, 10000);

                const storedProducts=localStorage.removeItem("products"); //Clears the product Cart

                setCartProduct(storedProducts);

                setFormData({
                  name: "",
                  company: "",
                  phone: "",
                  email: "",
                  country: "",
                  solution: ["N/A"],
                  products: storedProducts,
                  label_width: "",
                  label_height: "",
                  label_qty: "",
                  label_material: "",
                  label_shape: "",
                  label_no_of_colors: "",
                  label_colors: "",
                  label_des: "",
                  address: "",
                  city: "",
                  postalcode: "",
                  lamination: "",
                  environment: "",
                });
              } else {
                console.log("Error");
              }
            });
        }
      }
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setError(newErrors);
    }
  };

  // const handleSubmit = async (event) => {
  //   console.log("FormData", formData);
  //   event.preventDefault();

  //   try {
  //     // Clear previous errors
  //     console.log("Submitted", formData);
  //     setError({});
  //     const isValid = await validationSchema.validate(formData, {
  //       abortEarly: false,
  //     });
  //     if (
  //       isValid !== undefined &&
  //       isValidPhoneNumber == true &&
  //       formData.phone !== ""
  //     ) {

  //       if(formData.products || formData.solution != ['N/A'] || )
  //       {console.log("isValid", isValid);
  //       console.log("Submitted", formData);
  //       axios
  //         .post(
  //           "http://localhost:3007/api/v1/quotations/submit_quote",
  //           formData
  //         )
  //         .then((res) => {
  //           if (
  //             res.data.status === true &&
  //             res.data.response &&
  //             res.data.response.affectedRows > 0
  //           ) {
  //             setShowConfirmation(true);
  //             setTimeout(() => {
  //               setShowConfirmation(false);
  //             }, 10000);
  //             setFormData({
  //               name: "",
  //               company: "",
  //               phone: "",
  //               email: "",
  //               country: "",
  //               solution: ["N/A"],
  //               products: cartProduct,
  //               label_width: "",
  //               label_height: "",
  //               label_qty: "",
  //               label_material: "",
  //               label_shape: "",
  //               label_no_of_colors: "",
  //               label_colors: "",
  //               label_des: "",
  //               address: "",
  //               city: "",
  //               postalcode: "",
  //               lamination: "",
  //               environment: "",
  //             });

  //             // localStorage.removeItem("products"); //Clears the product Cart
  //           } else {
  //             console.log("Error");
  //           }
  //         });}
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     const newErrors = {};
  //     error.inner.forEach((err) => {
  //       newErrors[err.path] = err.message;
  //     });
  //     setError(newErrors);
  //   }
  // };

  useEffect(() => {
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/solutions/getsolutions")
      .then((res) => {
        setTest(res.data.response);
        console.log("test", test);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post("https://websiteportal.rajkamalbarscan.com/api/v1/label/get_active_label_category")
      .then((res) => {
        setLabelCategory(res.data.response);
        console.log("test", labelCategory);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="bg-white">
      <h1 className="font-poppins pt-24 mb-20 text-4xl  font-extrabold leading-none tracking-tight text-center  md:text-5xl lg:text-6xl dark:text-white">
        Quotation Page
      </h1>
      <div>
        <div className="box-border hover:box-content px-10 sm:px-20 md:px-40 lg:px-80">
          <Accordion className="px-1 text-left bg-gray-100">
            <Accordion.Panel className="">
              <Accordion.Title className="font-poppins border-2 border-black ">
                User Details
                {formData.name &&
                formData.company &&
                formData.phone &&
                formData.email &&
                formData.city &&
                formData.address &&
                formData.postalcode ? (
                  <AiOutlineCheckCircle className="inline-block text-[#008000] mr-5 ml-3" />
                ) : null}
              </Accordion.Title>

              <Accordion.Content className="">
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="name" value="Name" />
                  </div>
                  <TextInput
                    id="name"
                    type="text"
                    sizing="md"
                    name="name"
                    placeholder="Ken Nunes"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {Error.name && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.name}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="email4" value="Your email" />
                  </div>
                  <TextInput
                    id="email"
                    type="email"
                    name="email"
                    icon={HiMail}
                    value={formData.email}
                    placeholder="abc@gmail.com"
                    onChange={handleInputChange}
                    required
                  />
                  {Error.email && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.email}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="company" value="Company" />
                  </div>
                  <TextInput
                    id="company"
                    type="text"
                    sizing="md"
                    placeholder="Rajkamal Barscan Systems Pvt Ltd"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                </div>
                {Error.company && (
                  <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                    {Error.company}
                  </p>
                )}
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="address" value="Address" />
                  </div>
                  <Textarea
                    id="address"
                    type="text"
                    sizing="md"
                    name="address"
                    placeholder="A-36, Giriraj Industrial Estate, Mahakali Caves Rd, Andheri East"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                  {Error.address && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.address}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="city" value="City" />
                  </div>
                  <TextInput
                    id="city"
                    type="text"
                    sizing="md"
                    placeholder="Mumbai"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
                {Error.city && (
                  <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                    {Error.city}
                  </p>
                )}
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="postalcode" value="Postal/Zip" />
                  </div>
                  <TextInput
                    id="postalcode"
                    type="number"
                    sizing="md"
                    placeholder="400093"
                    name="postalcode"
                    value={formData.postalcode}
                    onChange={handleInputChange}
                  />
                </div>
                {Error.postalcode && (
                  <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                    {Error.postalcode}
                  </p>
                )}

                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="phone" value="Phone" />
                  </div>
                  <PhoneInput
                    className="mx-2 my-5 rounded-lg"
                    defaultCountry="in"
                    preferredCountries={["us", "gb", "in"]}
                    value={formData.phone}
                    placeholder="phone"
                    onPhoneNumberChange={handlePhoneNumberChange}
                  />
                  {!isValidPhoneNumber && (
                    <div style={{ color: "red" }}>
                      Please enter a valid phone number
                    </div>
                  )}
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                Solutions{" "}
                {!formData.solution.includes("N/A") ? (
                  <AiOutlineCheckCircle className="inline-block text-[#008000] mr-5 ml-2.5" />
                ) : null}
              </Accordion.Title>
              <Accordion.Content>
                <legend className="mb-12">Select Software Solutions</legend>
                <div className="flex flex-col gap-4" id="checkbox">
                  {test &&
                    test.map((solution, index) => (
                      <div className="flex gap-2" key={index}>
                        <div className="flex h-5 items-center" key={index}>
                          <Checkbox
                            id={`solution-${index}`}
                            className="text-xl"
                            onChange={() =>
                              handleCheckboxChange({
                                id: solution.id,
                                title: solution.title,
                                img: solution.image,
                                category: solution.category,
                                selling_price: null,
                                qty: 1,
                                quoted_price: null,
                                index: index,
                              })
                            }
                            checked={formData.solution.some(
                              (sol) => sol.id === solution.id
                            )}
                          />
                        </div>
                        <div
                          className="flex flex-col"
                          onClick={handleSolutionNavigation}
                        >
                          <Label htmlFor={`solution-${index}`}>
                            {solution.title}
                          </Label>
                        </div>
                      </div>
                    ))}
                  <div className="flex gap-2">
                    <div className="flex h-5 items-center">
                      <Checkbox
                        id="none"
                        onChange={() => handleCheckboxChange("N/A")}
                        checked={formData.solution.includes("N/A")}
                      />
                    </div>
                    <div className="flex flex-col">
                      <Label htmlFor="none">Not Applicable (N/A)</Label>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-5 ">
                  <a
                    href="#"
                    className="font-poppins items-center rounded-xl px-3 mx-2 btn btn-primary btn-sm mb-10"
                    onClick={handleSolutionNavigation}
                  >
                    <i className="mx-1 me-1 font-poppins"></i> View Solutions
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                Products{" "}
                {formData.products && formData.products.length !== 0 ? (
                  <AiOutlineCheckCircle className="inline-block text-[#008000] mr-5 ml-2.5" />
                ) : null}
              </Accordion.Title>
              <Accordion.Content>
                <div className="overflow-x-auto">
                  <Table hoverable>
                    <Table.Head>
                      <Table.HeadCell>Product name</Table.HeadCell>
                      <Table.HeadCell>Code</Table.HeadCell>
                      <Table.HeadCell>Quantity</Table.HeadCell>
                      {/* <Table.HeadCell> </Table.HeadCell> */}
                      <Table.HeadCell> </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {cartProduct &&
                        cartProduct.map((item, index) => (
                          <Table.Row
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            key={index}
                          >
                            <Table.Cell className=" px-3 pt-5 flex items-center whitespace-nowrap font-medium text-gray-900 dark:text-white ">
                              <Avatar
                                className=" px-1 "
                                img={item.img}
                                alt="avatar of Jese"
                                bordered
                                onClick={() => handleClick(item)}
                              />
                              {item.title}
                            </Table.Cell>
                            <Table.Cell>{item.product_code}</Table.Cell>
                            <Table.Cell>
                              {
                                <div className="quantity-field my-3 ">
                                  <button
                                    className=""
                                    onClick={() => handleDecrement(index)}
                                  >
                                    -
                                  </button>
                                  <input
                                    className="rounded-lg border text-center w-4/6"
                                    type="number"
                                    min="1"
                                    value={item.qty}
                                    onChange={(e) => {
                                      const newQty = parseInt(e.target.value);
                                      if (!isNaN(newQty)) {
                                        handleInputChange1(index, newQty);
                                      }
                                    }}
                                  />
                                  <button
                                    className=""
                                    onClick={() => handleIncrement(index)}
                                  >
                                    +
                                  </button>
                                </div>
                              }
                            </Table.Cell>

                            <Table.Cell>
                              <AiOutlineCloseCircle
                                className="text-4xl text-red-500 hover:scale-105 duration-300"
                                onClick={() => RemoveProduct(index)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                  <div className="flex justify-center mt-5 ">
                    <a
                      href="#"
                      className="font-poppins items-center rounded-xl px-3 mx-2 btn btn-primary btn-sm mb-10"
                      onClick={handleNavigate}
                    >
                      <i className="mx-1 me-1 font-poppins"></i> Add Products
                    </a>
                  </div>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                Labels{" "}
                {formData.label_width &&
                formData.label_height &&
                formData.label_qty &&
                formData.label_material &&
                formData.label_shape &&
                formData.label_no_of_colors &&
                formData.label_colors &&
                formData.label_des &&
                formData.lamination &&
                formData.environment ? (
                  <AiOutlineCheckCircle className="inline-block text-[#008000] mr-5 ml-2.5" />
                ) : null}
              </Accordion.Title>
              <Accordion.Content className="font-poppins">
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="label_width" value="Label Width (cm)" />
                  </div>
                  <TextInput
                    id="label_width"
                    type="number"
                    sizing="md"
                    name="label_width"
                    placeholder="2.5"
                    value={formData.label_width}
                    onChange={handleInputChange}
                  />
                </div>
                {Error.label_width && (
                  <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                    {Error.label_width}
                  </p>
                )}
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="label_height" value="Label Height (cm)" />
                  </div>
                  <TextInput
                    id="label_height"
                    type="number"
                    sizing="md"
                    placeholder="0.8"
                    name="label_height"
                    value={formData.label_height}
                    onChange={handleInputChange}
                  />
                  {Error.label_height && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_height}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="label_qty" value="Label Quantities" />
                  </div>
                  <TextInput
                    id="label_qty"
                    name="label_qty"
                    type="number"
                    sizing="md"
                    placeholder="10000"
                    value={formData.label_qty}
                    onChange={handleInputChange}
                  />
                  {Error.label_qty && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_qty}
                    </p>
                  )}
                </div>

                {/* <div className="">
                  <div className="my-2 block">
                    <Label
                      htmlFor="label_material"
                      value="Label Material (if any)"
                    />
                  </div>
                  <Dropdown label="Dropdown button">
                    {labelCategory.map((category) => (
                      <Dropdown.Item key={category.title} className="pl-0 ml-0">
                        {category.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>

                  {Error.label_material && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_material}
                    </p>
                  )}
                </div> */}

                <div className="">
                  <div className="my-2 block">
                    <Label
                      htmlFor="label_material"
                      value="Label Material (if any)"
                    />
                  </div>
                  <select
                    className="w-full h-auto "
                    id="myDropdown"
                    name="label_material"
                    onChange={handleInputChange}
                    value={formData.label_material || ""}
                  >
                    <option
                      value=""
                      name="label_material"
                      onChange={handleInputChange}
                    >
                      -- Select Material --
                    </option>
                    {labelCategory.map((category) => (
                      <option
                        className="rounded  px-5 "
                        value={category.title}
                        key={category.title}
                      >
                        {category.title}
                      </option>
                    ))}
                  </select>

                  {Error.label_material && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_material}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="my-2 block">
                    <Label htmlFor="label_material" value="Label Shape " />
                  </div>
                  <TextInput
                    id="label_shape"
                    type="text"
                    sizing="md"
                    name="label_shape"
                    placeholder="Rectangle"
                    value={formData.label_shape}
                    onChange={handleInputChange}
                  />
                  {Error.label_shape && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_shape}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="my-2 block">
                    <Label
                      htmlFor="label_no_of_colors"
                      value="Number of Colours "
                    />
                  </div>
                  <TextInput
                    id="label_no_of_colors"
                    type="number"
                    sizing="md"
                    name="label_no_of_colors"
                    placeholder="2"
                    value={formData.label_no_of_colors}
                    onChange={handleInputChange}
                  />
                  {Error.label_no_of_colors && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_no_of_colors}
                    </p>
                  )}
                </div>

                <div className="">
                  <div className="my-2 block">
                    <Label
                      htmlFor="label_colors"
                      value="Specific Ink Colours"
                    />
                  </div>
                  <TextInput
                    id="label_colors"
                    type="text"
                    sizing="md"
                    name="label_colors"
                    placeholder="Red, Yellow"
                    value={formData.label_colors}
                    onChange={handleInputChange}
                  />
                  {Error.label_colors && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_colors}
                    </p>
                  )}
                </div>

                <fieldset className="flex max-w-md flex-col">
                  <div className="my-2 block">
                    <Label htmlFor="lamination" value="Lamination" />
                  </div>
                  <div className="flex items-center gap-2 px-1 ">
                    <Radio
                      id="yes"
                      name="lamination"
                      value="YES"
                      className="mb-2"
                      onChange={handleInputChange}
                      checked={formData.lamination === "YES"}
                    />
                    <Label htmlFor="yes">Yes</Label>
                  </div>
                  <div className="flex items-center gap-2 px-1">
                    <Radio
                      id="no"
                      name="lamination"
                      value="NO"
                      className="mb-2"
                      onChange={handleInputChange}
                      checked={formData.lamination === "NO"}
                    />
                    <Label htmlFor="no">No</Label>
                  </div>
                  {Error.lamination && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.lamination}
                    </p>
                  )}
                </fieldset>

                <fieldset className="flex max-w-md flex-col">
                  <div className="my-2 block">
                    <Label htmlFor="label_colors" value="Environment" />
                  </div>
                  <div className="flex items-center gap-2 px-1">
                    <Radio
                      id="indoor-environment"
                      name="environment"
                      value="Indoor"
                      className="mb-2"
                      checked={formData.environment === "Indoor"}
                      onChange={handleInputChange}
                    />
                    <Label htmlFor="indoor-environment">Indoor</Label>
                  </div>
                  <div className="flex items-center gap-2 px-1">
                    <Radio
                      id="outdoor-environment"
                      name="environment"
                      value="Outdoor"
                      className="mb-2"
                      checked={formData.environment === "Outdoor"}
                      onChange={handleInputChange}
                    />
                    <Label htmlFor="outdoor-environment">Outdoor</Label>
                  </div>
                  {Error.environment && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.environment}
                    </p>
                  )}
                </fieldset>

                <div className="my-2 block ">
                  <Label
                    className="font-poppins"
                    htmlFor="label_des"
                    value="Label Requirement Details (if any)"
                  />
                  <Textarea
                    id="label_des"
                    placeholder="Label description . . ."
                    name="label_des"
                    value={formData.label_des}
                    onChange={handleInputChange}
                    required
                    rows={4}
                  />
                  {Error.label_des && (
                    <p className="text-red-500 font-semibold my-1 mx-2 text-sm py-0">
                      {Error.label_des}
                    </p>
                  )}
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>

          <div className="flex justify-center mt-5 ">
            <a
              href="#"
              className="font-poppins items-center rounded-xl px-4 mx-5 btn btn-primary btn-lg mb-10"
              onClick={handleSubmit}
            >
              <i className="mx-1 me-1 font-poppins"></i> Submit
            </a>
          </div>
          {showConfirmation ? (
            <Alert className="mb-5" color="success" onDismiss={toggleAlert}>
              <span className="font-medium">Success!</span> We will get back to
              you in 3-5 business days.
            </Alert>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default QuotationPage;
