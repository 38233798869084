import React, { useContext } from "react";
import Typed from "react-typed";
// import isSun from './Navbar';
// import { useTheme } from "../Contexts/ThemeContext";

const Hero = () => {
  // const { isSun, toggleTheme } = useTheme();
  // console.log("This is Data From Context Api", isSun, toggleTheme);
  return (
    <div>
      <div className="z-20 max-w-screen-lg w-full min-h-screen mx-auto text-center flex flex-col justify-center items-center">
        <h1
          className="font-montserrat md:text-7xl sm:text-6xl text-4xl font-bold md:pb-6"
          id="rkbs"
        >
          Rajkamal Barscan Systems Pvt Ltd
        </h1>
        {/* <button onClick={toggleTheme}>Hello</button> */}
        <div
          className={`md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 ${
             "text-[#00df9a]"
          }`}
        >
          {" "}
          {/* Wrap in a flex container */}
          <p
            className="font-montserrat md:text-5xl sm:text-4xl text-2xl font-bold py-4"
            id="typed"
          >
            Knowing Where Everything
          </p>
          <Typed
            className={`font-montserrat text-[#00df9a] md:text-5xl text-[sm:text-4xl text-xl font-bold md:pl-4 pl-2 `}
            strings={["WAS", "IS", "WILL BE"]}
            typeSpeed={120}
            backSpeed={140}
            loop
            smartBackspace={true}
            style={{ color: "#00df9a" }}
            // style={{ color: "#00df9a !important" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
