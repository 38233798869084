import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { ThemeProvider } from "./Contexts/ThemeContext";
import { CookiesProvider } from "react-cookie";
import { CartProvider  } from "./Contexts/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <CartProvider >
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </CartProvider >
  </React.StrictMode>
);
